import React from 'react';
import { formatPhoneNumber, onErrorImage } from '../../Common/validate';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import GoogleMapReact from 'google-map-react';
import { Modal } from 'react-bootstrap';

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 3
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 2
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

const Marker = () => <div><i class="fa fa-map-marker" aria-hidden="true"></i></div>;

const ViewLoopNet = ({ data, handleCloseModal, showModal, rejected, tabType }) => {

    const imageFormats = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'];
    const imagesList = (data.images && data.images.length > 0) ? data.images.filter(image => {
        const extension = image.url.split('.').pop().toLowerCase();
        return imageFormats.includes(extension);
    }) : [];

    return (
        <>
            <Modal show={showModal} dialogClassName="modal-90w  view-loopnet-data">
                <Modal.Header >
                    <Modal.Title>
                        <div class="property-header">
                            <h1>{data.header ? (data.header.headerAddress ? data.header.headerAddress : "") + " " + (data.header.location ? data.header.location : '') : ""}</h1>
                            <p>{data.header ? (data.header.subtext ? data.header.subtext : '') : ""}</p>
                        </div>
                    </Modal.Title>
                    <div style={{display:"flex" }}>
                    {tabType == 'new' ?
                            <>
                                <button type="button" className="btn btn-primary mr-3" onClick={() => rejected(data._id, data.ListingId, "cre")}><i className="fas fa-wallet mr-2"></i>CRE</button>
                                <button type="button" className="btn btn-primary mr-3" onClick={() => rejected(data._id, data.ListingId, "business")}><i className="fas fa-briefcase mr-2"></i>Business</button>
                                <button type="button" className="btn btn-primary mr-3" onClick={() => rejected(data._id, data.ListingId, "reject")}><i className="fa fa-ban mr-2"></i>Reject</button>
                                <button type="button" className="btn btn-primary mr-3" onClick={() => rejected(data._id, data.ListingId, "multifamily")}><i class="fas fa-users mr-2"></i>Multifamily</button>
                                <button type="button" className="btn btn-primary mr-3" onClick={() => rejected(data._id, data.ListingId, "land")}><i class="fas fa-mountain mr-2"></i>Land</button>
                            </>
                            : ""}
                        <i className="fa fa-times close_icon_modal" onClick={handleCloseModal}></i>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div class="container-fluid">
                        {imagesList && imagesList.length > 0 ?
                            <div className="property-images">
                                <Carousel responsive={responsive} >
                                    {imagesList.map((image, index) => {
                                        return (
                                            <div key={index} style={{ padding: '10px' }}>
                                                <img src={image.url} alt={`Slide ${index + 1}`} style={{ width: '100%', height: "350px", objectFit: 'cover', borderRadius: '10px' }} onError={onErrorImage} />
                                            </div>
                                        )
                                    })}
                                </Carousel>
                            </div>
                            : ""
                        }
                        {
                            data.Highlights && data.Highlights.length > 0
                                ?
                                <div class="highlights">
                                    <h2>Investment Highlights</h2>
                                    <ul>
                                        <div class="row">
                                            {
                                                data.Highlights.map((item, i) => {
                                                    return (
                                                        <div class="col-md-6" key={i}>
                                                            <li>{item}</li>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </ul>
                                </div>
                                : ""
                        }
                        {
                            data.Summary
                                ?
                                <div class="executive-summary">
                                    <h2>Executive Summary</h2>
                                    <p>{data.Summary}</p>
                                </div>
                                : ''
                        }
                        {
                            data.PropertyFacts && (data.PropertyFacts.Price || data.PropertyFacts.BuildingSize)
                                ?
                                <div className="property-facts">
                                    <h2>Property Facts</h2>
                                    <div className="row">
                                        {Object.keys(data.PropertyFacts).map((key, index) => (
                                            <div key={index} className="col-md-4">
                                                <p><strong>{key}</strong> : {data.PropertyFacts[key] ? data.PropertyFacts[key] : '---'}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                : ''
                        }
                        {
                            data.Amenities && data.Amenities.length > 0
                                ?
                                <div class="highlights">
                                    <h2>Amenities</h2>
                                    <ul>
                                        <div class="row">
                                            {
                                                data.Amenities.map((item, i) => {
                                                    return (
                                                        <div class="col-md-3" key={i}>
                                                            <li>{item}</li>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </ul>
                                </div>
                                : ""
                        }
                        {
                            data.citydetails && data.citydetails.location
                                ?
                                <div className="map-container">
                                    <h2>Map</h2>
                                    <div style={{ height: '60vh', width: '100%' }}>
                                        <GoogleMapReact
                                            bootstrapURLKeys={{ key: "AIzaSyB9BICTtxqbyg4eYSOMekzW-0M-ZWcIs3E" }}
                                            defaultCenter={{
                                                lat: data.citydetails.location.lat,
                                                lng: data.citydetails.location.lon
                                            }}
                                            defaultZoom={11}
                                        >
                                            <Marker
                                                lat={data.citydetails.location.lat}
                                                lng={data.citydetails.location.lon}
                                            />
                                        </GoogleMapReact>
                                    </div>
                                </div>
                                : ""
                        }
                        {
                            data.Transportation && data.Transportation.length > 0
                                ?
                                <div class="space-availability">
                                    <h2>Transportation</h2>
                                    <table class="table table-bordered">
                                        <tbody>
                                            {
                                                data.Transportation.map((item, index) => {
                                                    let details = item.stops[0] ? item.stops[0] : {};
                                                    return (
                                                        <tr key={index}>
                                                            <td>{item.vehicule + ", " + details.name} {details.logos && details.logos[0] ? <img src={details.logos && details.logos[0]} width="50" height="35" onError={onErrorImage} /> : ''}</td>
                                                            <td>{details.drive && details.drive.Display ? details.drive.Display + ' drive' : ''}</td>
                                                            <td>{details.dist && details.dist.Display ? details.dist.Display + ' dist' : ''}</td>
                                                            <td>{details.walk && details.walk.Display ? details.walk.Display + ' walk' : ''}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                : ''
                        }
                        {
                            data.PropertyTaxes && data.PropertyTaxes.length > 0
                                ?
                                <div className="property-facts">
                                    <h2>Property Taxes</h2>
                                    <div className="row">
                                        {data.PropertyTaxes.map((item, index) => (
                                            <div key={index} className="col-md-4">
                                                <p><strong>{item.Key}</strong> : {item.Value ? item.Value : "---"}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                : ''
                        }
                        {
                            data.SaleAdvisor && data.SaleAdvisor.length > 0
                                ?
                                <div class="space-availability">
                                    <h2>Sale Advisor</h2>
                                    <table class="table table-bordered">
                                        <thead class="thead-light">
                                            <tr>
                                                <th>Name</th>
                                                <th>Company</th>
                                                <th>Email</th>
                                                <th>Phone Number</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                data.SaleAdvisor.map((item, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{<img src={item.brokerImage} width="40" height="40" onError={onErrorImage} />} {item.name}</td>
                                                            <td>{<img src={item.companyLogo} width="40" height="40" onError={onErrorImage} />} {item.company}</td>
                                                            <td>{item.email}</td>
                                                            <td>{item.phoneInfo && item.phoneInfo.phoneNumber ? "+" + item.phoneInfo.phoneNationalPrefix + " " + formatPhoneNumber(item.phoneInfo.phoneNumber, item.phoneInfo.phoneCountryCode) : ""}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                : ''
                        }
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ViewLoopNet;
