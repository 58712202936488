import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { fetchHandleInputUser } from '../../Action/financialledAction';
import ImportedURL from '../../Common/api';
import { Error, Success } from '../../Common/swal';
import axios from 'axios';
import validator from 'validator';

const Addfinanciallead = ({ show, handleClose, modalType, id = '' }) => {
    const userReducer = useSelector(state => state.financelead);
    const data = userReducer.user ? userReducer.user : {};
    const dispatch = useDispatch();

    const [nameError, setNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [addressError, setAddressError] = useState(false);
    const [mobileError, setMobileError] = useState(false);
    const [savingSpinner, setSaveSpinner] = useState(false);
    const [emailValidError, setEmailValidError] = useState(false);

    const onChange = (e) => {
        const { name, value } = e.target;
        dispatch(fetchHandleInputUser(name, value));
    
        // Reset error states on change
        setNameError(false);
        setEmailError(false);
        setAddressError(false);
        setMobileError(false);
        setEmailValidError(false);
    
        // Check for errors based on the field name
        switch (name) {
            case "name":
                if (!value) {
                    setNameError(true);
                }
                break;
            case "email":
                if (!value) {
                    setEmailError(true);
                } else if (!validator.isEmail(value) || !isValidEmailTLD(value)) {
                    setEmailValidError(true);
                }
                break;
            case "address":
                if (!value) {
                    setAddressError(true);
                }
                break;
                case "mobile":
                    // Ensure value is not blank and contains only digits
                    if (!value || !/^[\d\(\)\-\s]+$/.test(value)) {
                        setMobileError(true);
                    } else {
                        setMobileError(false); // Reset the error if valid
                    }
                break;
            default:
                break;
        }
    };
    
    // Helper function to check TLD for email
    const isValidEmailTLD = (email) => {
        const tld = email.split('.').pop();
        return tld.length >= 2 && tld.length <= 3;
    };
    

    const submit = () => {
        let newData = { ...data };
        for (let key in newData) {
            if (newData.hasOwnProperty(key) && typeof newData[key] === 'string') {
                newData[key] = newData[key].trim();
            }
        }
    
        let valid = 1; // Start as valid
        // Validate fields
        if (!newData.name) {
            setNameError(true);
            valid = 0; // Set valid to false
        }
        if (!newData.email) {
            setEmailError(true);
            valid = 0;
        }
        if (emailValidError) {
            valid = 0;
        }
        if (!newData.address) {
            setAddressError(true);
            valid = 0;
        }
        if (!newData.mobile || !/^[\d\(\)\-\s]+$/.test(newData.mobile)) {
            setMobileError(true);
            valid = 0;
        }
    
        if (valid) {
            setSaveSpinner(true);
            const apiCall = modalType === "Add"
                ? axios.post(ImportedURL.API.addfinanciallead, newData)
                : axios.post(`${ImportedURL.API.updatefinanciallead}/${id}`, newData);
    
            apiCall.then((res) => {
                const { message } = res.data || {};
                Success(message);
                reset(); // Reset only after successful submission
                handleClose(true);
            }).catch(({ response }) => {
                const { message } = response.data || {};
                Error(message);
            }).finally(() => {
                setSaveSpinner(false);
            });
        }
    };
    

    const reset = () => {
        setNameError(false);
        setEmailError(false);
        setAddressError(false);
        setMobileError(false);
        setSaveSpinner(false);
        setEmailValidError(false);
    }

    const handleCloseSubmit = () => {
        handleClose();
        reset();
    }

    return (
        <>
            <Modal show={show}>
                <Modal.Header>
                    <Modal.Title>{modalType + " User"}</Modal.Title>
                    <i className="fa fa-times close_icon_modal" onClick={handleCloseSubmit}></i>
                </Modal.Header>
                <Modal.Body>
                    {savingSpinner && <div className="spinner-border" role="status"><span className="sr-only">Loading...</span></div>}
                    <div className="">
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label className="form-label">Name<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                    <input type="text" className="form-control" name="name" value={data.name || ""} onChange={onChange} placeholder="Name" />
                                    <div className="invalid-feedback" style={{ display: nameError ? "block" : 'none' }}>Name is required</div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <label className="form-label">Email<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                    <input type="text" className="form-control" name="email" value={data.email || ""} onChange={onChange} placeholder="Email" />
                                    <div className="invalid-feedback" style={{ display: emailError ? "block" : 'none' }}>Email is required</div>
                                    <div className="invalid-feedback" style={{ display: emailValidError ? "block" : 'none' }}>Enter valid email</div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <label className="form-label">Mobile No<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                    <input type="text" className="form-control" name="mobile" value={data.mobile || ""} onChange={onChange} placeholder="Mobile No" />
                                    <div className="invalid-feedback" style={{ display: mobileError ? "block" : 'none' }}>Mobile number is required</div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <label className="form-label">Address<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                    <input type="text" className="form-control" name="address" value={data.address || ""} onChange={onChange} placeholder="Address" />
                                    
                                    <div className="invalid-feedback" style={{ display: addressError ? "block" : 'none' }}>Address is required</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseSubmit}>
                        <i className="fa fa-times mr-2"></i>Close
                    </Button>
                    <Button variant="primary" onClick={submit} disabled={savingSpinner}>
                        {savingSpinner ? 
                            <i className="fa fa-spinner fa-spin mr-2"></i> 
                            : <i className="fas fa-download mr-2"></i>}
                        {modalType === "Add" ? "Save" : "Update"}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Addfinanciallead;
