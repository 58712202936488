const LIVE = true;
const ENV = true ? 'prod' : 'dev';
const SITEURL = ENV === 'prod' ? "https://loopnet.myhotelai.com/" : "https://testloopnets.myaistar.com/";
const LIVEURL = LIVE ? SITEURL : "http://localhost:6065/";
const ROOTURL = LIVEURL + 'api/v1/';

const API = {
    login: ROOTURL + 'user/login',
    changepassword: ROOTURL + 'user/changepassword',
    forgotpassword: ROOTURL + 'user/forgotpassword',
    accountdetails: ROOTURL + 'user/accountdetails',
    adduser: ROOTURL + 'user/adduser',
    updateuser: ROOTURL + 'user/updateuser',
    listuser: ROOTURL + 'user/listuser',
    viewuser: ROOTURL + 'user/viewuser',
    changestatus: ROOTURL + 'user/changestatus',
    changefinanceleadstatus: ROOTURL + 'user/changefinanceleadstatus',

    listloopnetdata: ROOTURL + 'loopnet/listloopnetdata',
    downloadPdf: ROOTURL + 'loopnet/downloadPdf',
    sendmailwithpdf: ROOTURL + 'loopnet/sendmailwithpdf',
    viewloopnetdata: ROOTURL + 'loopnet/viewloopnetdata',
    updatecities: ROOTURL + 'loopnet/updatecities',
    updatedownloadcsvstatus: ROOTURL + 'loopnet/updatedownloadcsvstatus',
    updaterejectstatus: ROOTURL + 'loopnet/updaterejectstatus',
    markrejectstatus: ROOTURL + 'loopnet/markrejectstatus',
    updaterejectcities: ROOTURL + 'loopnet/updaterejectcities',
    updateloopnetdata: ROOTURL + 'loopnet/updateloopnetdata',

    getdashboardcount: ROOTURL + 'dashboard/getdashboardcount',

    listcitiesandstate: ROOTURL + 'usacities/listcitiesandstate',
    listvalidcities: ROOTURL + 'usacities/listvalidcities',
    updatecitystatus: ROOTURL + 'usacities/updatecitystatus',
    getcityandstatelist: ROOTURL + 'usacities/getcityandstatelist',

    getallfinancialleads: ROOTURL + 'financiallead/getallfinancialleads',
    getFinancialLeadList: ROOTURL + 'financiallead/getFinancialLeadList',
    addfinanciallead: ROOTURL + 'financiallead/addfinanciallead',
    updatefinanciallead: ROOTURL + 'financiallead/updatefinanciallead',
    changefinanceleadstatus: ROOTURL + 'financiallead/changefinanceleadstatus',
}

const ImportedURL = {
    API: API,
}

export default ImportedURL;
