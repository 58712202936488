import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

const AgGridCustomizePagination = (props) => {

    const onclickPage = (page) => {
        const { perPage = 25, currentPage = 1 } = props;
        const totalPages = Math.ceil(props.total / perPage);
        if (page >= 1 && page <= totalPages && page !== currentPage) {
            props.onPagination(page);
        }
    };

    const handlePerPageChange = (event) => {
        props.onPerPageChange(Number(event.target.value));
    };

    const { perPage = 25, currentPage = 1, getRowStyle = null, defaultColDef, gridApiRef, columnDefs, rowData, total, onRowClicked, onGridReady, onFilterChanged, onSelectionChanged, onSortChanged } = props;
    const isEmptyRowData = rowData.length === 0;
    const totalPages = Math.ceil(total / perPage);

    return (
        <>
            <div className="table-responsive customize-pagination-ag-agrid">
                <div className="ag-theme-alpine">
                    <AgGridReact
                        ref={gridApiRef}
                        paginationPageSize={perPage}
                        pagination={false}
                        rowData={rowData}
                        floatingFilter={true}
                        onRowClicked={(e) => onRowClicked(e)}
                        domLayout={"autoHeight"}
                        defaultColDef={defaultColDef}
                        onGridReady={(e) => onGridReady(e)}
                        columnDefs={columnDefs}
                        animateRows={true}
                        overlayNoRowsTemplate={"No rows to display"}
                        rowSelection="multiple"
                        onSelectionChanged={onSelectionChanged}
                        suppressRowClickSelection={true}
                        onFilterChanged={onFilterChanged}
                        getRowStyle={getRowStyle}
                        onSortChanged={(e) => onSortChanged(e)}
                    />
                    <div className="pagination-row">
                        <div className="pagination-page-size-panel">
                            <label >Page Size:</label>
                            <select id="perPage" value={perPage} onChange={handlePerPageChange}>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                            </select>
                        </div>
                        <div className="pagination-row-summary-panel">
                            <span className='pagination-row-summary-value'>{isEmptyRowData ? 0 : (((currentPage - 1) * perPage) + 1)}</span>
                            <span className='pagination-row-summary-label'>to</span>
                            <span className='pagination-row-summary-value'>{Math.min(currentPage * perPage, total)}</span>
                            <span className='pagination-row-summary-label'>of</span>
                            <span className='pagination-row-summary-value'>{total}</span>
                        </div>
                        <div className="pagination-page-summary-panel">
                            <span className='pagination-page-summary-icon' onClick={() => onclickPage(1)}><i class="ag-icon ag-icon-first" /></span>
                            <span className='pagination-page-summary-icon' onClick={() => onclickPage(Math.max(currentPage - 1, 1))}><i class="ag-icon ag-icon-previous" /></span>
                            <span className='pagination-page-description'>
                                <span className='pagination-page-of-label'>Page</span>
                                <span className='pagination-page-of-value'>{isEmptyRowData ? 0 : currentPage}</span>
                                <span className='pagination-page-of-label'>of</span>
                                <span className='pagination-page-of-value'>{totalPages}</span>
                            </span>
                            <span className='pagination-page-summary-icon' onClick={() => onclickPage(Math.min(currentPage + 1, totalPages))}><i class="ag-icon ag-icon-next" /></span>
                            <span className='pagination-page-summary-icon' onClick={() => onclickPage(totalPages)}><i class="ag-icon ag-icon-last" /></span>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
};

export default AgGridCustomizePagination;