const initialState = {
    spinner: false,
    modalspinner: false,
    listuser: [],
    user: {},
    total: 0,
};

const dataReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SPINNER_USER":
            return {
                ...state,
                spinner: true
            };
        case "MODAL_SPINNER_USER":
            return {
                ...state,
                modalspinner: true
            };
        case "LIST_USER":
            return {
                ...state,
                spinner: action.spinner,
                total: action.total,
                listuser: action.payload
            };
        case "VIEW_USER":
            return {
                ...state,
                modalspinner: action.spinner,
                user: action.payload
            };
        case 'HANDLE_USER_CHANGE':
            return Object.assign({}, state, {
                user: {
                    ...state.user,
                    [action.name]: action.value
                }
            })
        case "RESET_USER":
            return {
                ...state,
                user: {}
            };

        default:
            return state;
    }
};

export default dataReducer;
