import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import SpinnerComponent from '../../Utilities/Spinner/spinner';
import Select from 'react-select';
import RowSelectAggridComponent from '../../Utilities/Ag-Grid/rowselectaggridcomponents';
import { fetchDataSpinner, listFetchData } from '../../Action/citiesAction';
import axios from 'axios';
import ImportedURL from '../../Common/api';
import { Error, Success } from '../../Common/swal';
import Swal from 'sweetalert2';
import MultiSelectComponent from '../../Utilities/SelectOption/multiselect';
import moment from 'moment';
import { getSortModelAlternative } from '../../Common/validate';

const defaultColDef = {
    editable: false,
    sortable: true,
    resizable: true,
    filter: true,
    flex: 1,
    minWidth: 100,
    filterParams: { newRowsAction: 'keep' },
}

const ListFetchData = () => {
    const gridApiRef = useRef(null);

    const [perPage, setPerPage] = useState(25);
    const [currentPage, setCurrentPage] = useState(1);
    const [sort, setSort] = useState("");
    const [filter, setFilter] = useState("");
    const [gridApi, setGridApi] = useState(null);
    const [listSpinner, setListSpinner] = useState(false);

    const [state, setState] = useState("");
    const [city, setCity] = useState([]);
    const [citySelectAll, setCitySelectAll] = useState(true);
    const [stateError, setStateError] = useState(false);
    const [cityError, setCityError] = useState(false);
    const [saving, setSaving] = useState(false);

    const [columnDefs] = useState([
        {
            headerName: "City", field: "cityname", sortable: true, floatingFilter: true,
            valueGetter: function (params) {
                if (params.data && params.data.cityname)
                    return `${params.data ? params.data.cityname : ''}`
                else
                    return '---'
            }
        },
        {
            headerName: "State", field: "statename", sortable: true, floatingFilter: true,
            valueGetter: function (params) {
                if (params.data && params.data.statename)
                    return `${params.data ? params.data.statename : ''}`
                else
                    return '---'
            }
        },
        {
            headerName: "Last Fetch Date", field: "lastfetchdate", sortable: true, floatingFilter: true,
            valueGetter: function (params) {
                if (params.data && params.data.lastfetchdate)
                    return `${moment(params.data.lastfetchdate).format("MM-DD-YYYY hh:mm A")}`
                else
                    return '---'
            }
        },
        {
            headerName: "Fetching Status", field: "fetchstatus", sortable: true, floatingFilter: true,
            valueGetter: function (params) {
                if (params.data && params.data.changefetchstatus)
                    return params.data.changefetchstatus
                else
                    return '---'
            },
            cellRenderer: function (params) {
                let btn = `---`;
                if (params.data && params.data.fetchstatus) {
                    if (params.data.fetchstatus == 'inprogress') {
                        btn = `<button type="button" class="btn btn-warning btn-xs fetch-data-btn fetch-cursor-none">Inprogress</button>`
                    } else if (params.data.fetchstatus == 'completed') {
                        btn = `<button type="button" class="btn btn-success btn-xs fetch-data-btn fetch-cursor-none">Completed</button>`
                    } else if (params.data.fetchstatus == 'rejected') {
                        btn = `<button type="button" title="Click to retry" class="btn btn-danger btn-xs fetch-data-btn" data-action-type="Rejected">Failed Request</button>`
                    } else if (params.data.fetchstatus == 'retry') {
                        btn = `<button type="button" title="Click to retry" class="btn btn-info btn-xs fetch-data-btn" data-action-type="Rejected">No Data Found</button>`
                    }
                }
                return <div style={{ textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: btn }}></div>;
            }

        },
        {
            headerName: 'Actions', field: 'actions', suppressMenu: true, sortable: false, filter: false,
            cellRenderer: function (params) {
                return <div dangerouslySetInnerHTML={{ __html: params.value }}></div>;
            }
        },
    ]);

    const dispatch = useDispatch();

    const onFetchData = (query = {}) => {
        const formData = {
            perPage: perPage,
            currentPage: currentPage,
            filter: filter,
            sort: sort,
            ...query
        }
        dispatch(fetchDataSpinner());
        dispatch(listFetchData(formData));
    }

    useEffect(() => {
        onFetchData();
    }, []);


    const data = useSelector(state => state.cities);
    const statelist = data.statelist ? data.statelist : [];
    const citylist = data.citylist ? data.citylist : [];
    const rowData = data.listfetchdata ? data.listfetchdata : [];
    const total = data.total ? data.total : 0;
    const spinner = data.spinner ? data.spinner : false;

    const deleted = `<button title="Delete" type="button" class="btn btn-icon" data-action-type="Delete" data-toggle="modal" data-target="#viewmodal"><i class="fa fa-trash" data-action-type="Delete"></i></button>`;

    if (rowData && rowData.length > 0) {
        rowData.forEach(object => {
            object.actions = deleted;
        });
    }

    const onRowClicked = async (event) => {
        const { _id, status } = event.data;
        const value = event.event.target.getAttribute('data-action-type');
        if (value === 'Delete') {
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: "btn btn-primary ",
                    cancelButton: "btn btn-default mr-3"
                },
                buttonsStyling: false
            });
            swalWithBootstrapButtons.fire({
                title: "Are you sure you want to delete?",
                text: "",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    setListSpinner(true);
                    axios.post(ImportedURL.API.updatecitystatus, { _id: _id, status: false })
                        .then((res) => {
                            onFetchData();
                            Success("Deleted successfully");
                            setListSpinner(false);
                        }).catch((err) => {
                            setListSpinner(false);
                        });
                }
            });
        }
        if (value === 'Rejected') {
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: "btn btn-primary ",
                    cancelButton: "btn btn-default mr-3"
                },
                buttonsStyling: false
            });
            swalWithBootstrapButtons.fire({
                title: "Are you fetching the data again?",
                text: "",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    setListSpinner(true);
                    axios.post(ImportedURL.API.updaterejectcities, { _id: _id })
                        .then((res) => {
                            onFetchData();
                            Success("Data fetched successfully");
                            setListSpinner(false);
                        }).catch(({ response }) => {
                            const { message } = response.data ? response.data : {}
                            Error(message);
                            setListSpinner(false);
                        });
                }
            });
        }
    };

    const onGridReady = (params) => {
        setGridApi(params.api);
    };

    const onPagination = (page) => {
        setCurrentPage(page);
        onFetchData({ currentPage: page });
    };

    const onPerPageChange = (num) => {
        setCurrentPage(1);
        setPerPage(num);
        onFetchData({ currentPage: 1, perPage: num });
    };

    const onFilterChanged = (event) => {
        const newFilter = event.api.getFilterModel();
        setCurrentPage(1);
        setFilter(newFilter);
        onFetchData({ currentPage: 1, filter: newFilter });
    };

    const onSortChanged = (event) => {
        const sortModel = getSortModelAlternative(event.columnApi);
        setCurrentPage(1);
        setSort(sortModel);
        onFetchData({
            currentPage: 1,
            sort: sortModel,
        });
    };

    const cityOptions = []
    const stateOptions = []
    if (statelist && statelist.length > 0) {
        statelist.map((item) => {
            stateOptions.push({ value: item.statename, label: item.statename, _id: item._id, name: 'state' })
        })
    }
    if (citylist && citylist.length > 0) {
        citylist.map((item) => {
            if (state && state.value == item.statename) {
                cityOptions.push({ value: item.cityname, label: item.cityname, _id: item._id, name: 'city' })
            }
        })
    }
    const showChargeCodeAll = cityOptions.filter((e) => !(city && city.some((o) => e.value == o.value)));
    if (showChargeCodeAll.length > 1 && cityOptions.length != city.length && citySelectAll) {
        cityOptions.unshift({ label: "All", value: 'All', name: 'city' })
    }

    const onChangeSelect = (event) => {
        const { name, value, selectAll } = event;
        if (name == 'state') {
            setStateError(false);
            setState(event);
            setCity("");
        }
        if (name == 'city') {
            setCityError(false);
            setCity(value);
            setCitySelectAll(selectAll);
        }
    };

    const submit = () => {
        let valid = 1;
        if (!state) {
            setStateError(true);
            valid = 0;
        }
        if (city.length === 0) {
            setCityError(true);
            valid = 0;
        }
        if (valid) {
            setSaving(true);
            const statusCity = city.some((o) => o.label === "All");
            const formData = {
                statename: state.value ? state.value : '',
                cityname: statusCity ? cityOptions : city,
            }
            axios.post(ImportedURL.API.updatecities, formData)
                .then((res) => {
                    setState("");
                    setCity("");
                    onFetchData();
                    Success("Added successfully");
                    setSaving(false);
                }).catch((err) => {
                    setSaving(false);
                });
        }
    }






    return (
        <>
            <div class="content-wrapper">
                <div class="content-header">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-sm-6">
                                <h1 class="m-0">Fetch Data</h1>
                            </div>
                            <div class="col-sm-6">
                                <ol class="breadcrumb float-sm-right">
                                    <li class="breadcrumb-item"><Link to={"/"}>Dashboard</Link></li>
                                    <li class="breadcrumb-item active">Fetch Data</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section class="content">
                    <div class="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-3">
                                                <div className="form-group">
                                                    <label className="form-label">State</label>
                                                    <Select
                                                        value={state}
                                                        onChange={onChangeSelect}
                                                        options={stateOptions}
                                                    />
                                                    <div className="invalid-feedback" style={{ display: stateError ? "block" : 'none' }}>State is required</div>
                                                </div>
                                            </div>
                                            <div className="col-7">
                                                <div className="form-group">
                                                    <label className="form-label">City</label>
                                                    <MultiSelectComponent name={'city'} options={cityOptions} value={city} citySelectAll={citySelectAll} onChange={onChangeSelect} />
                                                    <div className="invalid-feedback" style={{ display: cityError ? "block" : 'none' }}>City is required</div>
                                                </div>
                                            </div>
                                            <div className={`col-2 d-flex ${stateError || cityError ? "align-items-center" : "align-items-end"}`}>
                                                <div className="form-group">
                                                    {
                                                        saving ?
                                                            <button type="button" className="btn btn-primary mr-2"><i className="fa fa-spinner fa-spin mr-2"></i>Add</button>
                                                            :
                                                            <button type="button" className="btn btn-primary mr-2" onClick={submit}><i className="fa fa-plus mr-2"></i>Add</button>
                                                    }
                                                </div>
                                            </div>
                                            <RowSelectAggridComponent
                                                perPage={perPage}
                                                currentPage={currentPage}
                                                onRowClicked={onRowClicked}
                                                onGridReady={onGridReady}
                                                rowData={rowData}
                                                defaultColDef={defaultColDef}
                                                columnDefs={columnDefs}
                                                total={total}
                                                onPagination={onPagination}
                                                onPerPageChange={onPerPageChange}
                                                gridApiRef={gridApiRef}
                                                onFilterChanged={onFilterChanged}
                                                onSortChanged={onSortChanged}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <SpinnerComponent spinner={spinner || listSpinner} />
                </section >
            </div >

        </>
    )
}

export default ListFetchData
