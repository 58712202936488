import axios from 'axios';
import ImportedURL from '../Common/api';

export function fetchLoopnetData(formData = {}) {
    return function (dispatch) {
        return axios.post(ImportedURL.API.listloopnetdata, formData)
            .then((res) => {
                dispatch({ type: "LIST_LOOPNET_DATA", payload: res.data && res.data.data ? res.data.data : [], total: res.data && res.data.total ? res.data.total : [], spinner: false })
                return res.data;
            }).catch((err) => {
                dispatch({ type: "LIST_LOOPNET_DATA", payload: [], total: 0, spinner: false })
            });
    };
}

export function fetchViewLoopnetData(id = "") {
    return function (dispatch) {
        return axios.get(ImportedURL.API.viewloopnetdata + "/" + id)
            .then((res) => {
                const { data } = res.data ? res.data : {};
                dispatch({ type: "VIEW_LOOPNET_DATA", payload: data, spinner: false })
            }).catch((err) => {
                dispatch({ type: "VIEW_LOOPNET_DATA", payload: {}, spinner: false })
            });
    };
}

export function fetchfinancealleadData(id = "") {
    return function (dispatch) {
        return axios.get(ImportedURL.API.getallfinancialleads + "/" + id)
            .then((res) => {
                const { data } = res.data ? res.data : {};
                dispatch({ type: "VIEW_FINANCEALLLEAD_DATA", payload: data, spinner: false })
            }).catch((err) => {
                dispatch({ type: "VIEW_FINANCEALLLEAD_DATA", payload: {}, spinner: false })
            });
    };
}

export function fetchLoopnetDataSpinner() {
    return function (dispatch) {
        dispatch({ type: "SPINNER_LOOPNET_DATA" })
    };
}
