const initialState = {
    account: {},
    spinner: false,
};

const dataReducer = (state = initialState, action) => {
    switch (action.type) {
        case "ACCOUNT_DETAILS":
            return {
                ...state,
                account: action.payload,
                spinner: action.spinner,
            };
        case "ACCOUNT_SPINNER":
            return {
                ...state,
                spinner: true
            };


        default:
            return state;
    }
};

export default dataReducer;
