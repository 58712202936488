import axios from 'axios';
import ImportedURL from '../Common/api';

export function fetchAccountDetails() {
    return function (dispatch) {
        return axios.get(ImportedURL.API.accountdetails)
            .then((res) => {
                dispatch({ type: "ACCOUNT_DETAILS", payload: res.data && res.data.data ? res.data.data : {}, spinner: false })
                return res.data;
            }).catch((err) => {
                dispatch({ type: "ACCOUNT_DETAILS", payload: {}, spinner: false })
            });
    };
}

export function fetchAccountSpinner() {
    return function (dispatch) {
        dispatch({ type: "ACCOUNT_SPINNER" })
    };
}

