import React, { useState } from 'react'
import Logo from '../../Assets/Images/cornovuscapital-logo.png';
import ImportedURL from '../../Common/api';
import { Error, Success } from '../../Common/swal';
import axios from 'axios';
import { Link } from 'react-router-dom';

const ForgotPassword = () => {
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState(false);
    const [saveSpinner, setSaveSpinner] = useState(false);

    const onchange = (e) => {
        const { value, name } = e.target;
        if (name == 'email') {
            setEmail(value);
            setEmailError(false);
        }
    }

    const submit = () => {
        const formData = {
            email: email ? (email.trim()) : "",
        }
        let valid = 1;
        if (!formData.email) {
            setEmailError(true);
            valid = 0;
        }
        if (valid) {
            setSaveSpinner(true);
            axios.post(ImportedURL.API.forgotpassword, formData)
                .then((res) => {
                    const { message } = res.data ? res.data : {};
                    Success(message);
                    setSaveSpinner(false);
                    setEmail("");
                }).catch(({ response }) => {
                    const { message } = response.data ? response.data : {}
                    Error(message);
                    setSaveSpinner(false);
                });
        }

    }

    return (
        <>
            <div class="login-box">
                <div class="card card-outline card-primary">
                    <div class="card-header text-center">
                        <img src={Logo} alt="AdminLTE Logo" width={"310px"} />
                    </div>
                    <div class="card-body">
                        <p class="login-box-msg">Forgot password</p>
                        <p class="register-box-msg-forgot">Enter your email address and your password will be reset and emailed to you.</p>
                        <form action="recover-password.html" method="post">
                            <div class="input-group mb-3">
                                <input type="email" class="form-control" placeholder="Email" name='email' value={email ? email : ''} onChange={onchange} />
                                <div class="input-group-append">
                                    <div class="input-group-text">
                                        <span class="fas fa-envelope"></span>
                                    </div>
                                </div>
                                <div className="invalid-feedback" style={{ display: emailError ? "block" : 'none' }}>Email is required</div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    {saveSpinner ?
                                        <button type="button" class="btn btn-primary btn-block"><i class="fa fa-spinner fa-spin mr-2"></i>Sending</button>
                                        :
                                        <button type="button" class="btn btn-primary btn-block" onClick={submit}><i class="fas fa-sign-in-alt mr-2"></i>Send</button>
                                    }
                                </div>
                            </div>
                        </form>
                        <p class="mt-3 mb-1 text-center">
                            <Link to="/">Forget it, Send me Back</Link>
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ForgotPassword
