import axios from 'axios';
import ImportedURL from '../Common/api';
export function fetchfinancealleadData(formData = {}) {
    return function (dispatch) {
        return axios.post(ImportedURL.API.getallfinancialleads, formData)
            .then((res) => {
                dispatch({ type: "VIEW_FINANCEALLLEAD_DATA", payload: res.data && res.data.data ? res.data.data : [], total: res.data && res.data.total ? res.data.total : [], spinner: false })
                return res.data;
            }).catch((err) => {
                dispatch({ type: "VIEW_FINANCEALLLEAD_DATA", payload: [], total: 0, spinner: false })
            });
    };
}
export function getFinancialLeadList() {
    return function (dispatch) {
       return axios.get(ImportedURL.API.getFinancialLeadList) // No body parameters
            .then((res) => {
                const data = res.data?.data || [];
                const total = res.data?.total || 0;

                dispatch({
                    type: "GET_FINDATA",
                    payload: data,
                    total: total,
                });
                return res.data; // Optional return for further handling
            })
            .catch((err) => {
                dispatch({ type: "GET_FINDATA", payload: [], total: 0 });
            });
    };
}



export function fetchViewFinancialLead(data) {
    return function (dispatch) {
        dispatch({ type: "VIEW_FINANCE_LEAD", payload: data, spinner: false })
    };
}

export function fetchFinancialLeadSpinner() {
    return function (dispatch) {
        dispatch({ type: "SPINNER_FINANCE_LEAD" })
    };
}

export function fetchFinancialLeadModalSpinner() {
    return function (dispatch) {
        dispatch({ type: "MODAL_SPINNER_FINANCE_LEAD" })
    };
}

export function fetchHandleInputUser(name, value) {
    return function (dispatch) {
        dispatch({ type: "HANDLE_USER_CHANGE", name: name, value: value })
    };
}

export function fetchResetUser() {
    return function (dispatch) {
        dispatch({ type: "RESET_USER" })
    };
}