import React from 'react'
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';

const Navbar = () => {
    const accountReducer = useSelector(state => state.account);
    const account = accountReducer.account ? accountReducer.account : {};

    const logout = () => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: "btn btn-primary ",
                cancelButton: "btn btn-default mr-3"
            },
            buttonsStyling: false
        });
        swalWithBootstrapButtons.fire({
            title: "Are you sure want to logout?",
            text: "",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                localStorage.setItem('cornovuscapitaltoken', "");
                localStorage.setItem('role', "");
                window.location.href = "/";
            }
        });
    }
    return (
        <>
            <nav class="main-header navbar navbar-expand navbar-white navbar-light">
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a>
                    </li>
                </ul>
                <ul class="navbar-nav ml-auto card-body-profile">
                    <li class="nav-item dropdown">
                        <a class="nav-link" data-toggle="dropdown" href="#">
                            <i class="fas fa-user-circle"></i>
                        </a>
                        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                            <div>
                                <div class="form-group">
                                    <i class="fas fa-user mr-2"></i> {account.name ? account.name : "---"}
                                </div>
                                <div class="dropdown-divider"></div>
                                <div class="form-group">
                                    <i class="fas fa-envelope mr-2"></i> {account.email ? account.email : "---"}
                                </div>
                                {
                                    account.designation
                                        ?
                                        <>
                                            <div class="dropdown-divider"></div>
                                            <div class="form-group">
                                                <i class="fas fa-tag mr-2"></i> {account.designation ? account.designation : "---"}
                                            </div>
                                        </>
                                        : ""
                                }
                            </div>
                            <div class="dropdown-divider"></div>
                            <div class="dropdown-item dropdown-footer" onClick={logout}>
                                <i class="fas fa-sign-out-alt mr-2"></i>Logout
                            </div>
                        </div>
                    </li>
                    {/* <li class="nav-item">
                        <div class="nav-link">
                            <i class="fas fa-sign-out-alt" style={{ cursor: 'pointer' }} onClick={logout}></i>
                        </div>
                    </li> */}
                </ul>
            </nav>

        </>
    )
}

export default Navbar
