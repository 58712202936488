const initialState = {
    spinner: false,
    modalspinner: false,
    financeLeads: [],
    user: {},
    total: 0,
};

const dataReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SPINNER_FINANCE_LEAD":
            return {
                ...state,
                spinner: true
            };
        case "MODAL_SPINNER_FINANCE_LEAD":
            return {
                ...state,
                modalspinner: true
            };
        case "VIEW_FINANCEALLLEAD_DATA":
            return {
                ...state,
                spinner: action.spinner,  // Typically spinner will be false after loading data
                total: action.total,
                financeLeads: action.payload
            };
        case "VIEW_FINANCE_LEAD":
            return {
                ...state,
                modalspinner: action.spinner,  // Again, typically spinner will be false here
                user: action.payload
            };
        case 'HANDLE_USER_CHANGE':
            return {
                ...state,
                user: {
                    ...state.user,
                    [action.name]: action.value
                }
            };
        case "RESET_USER":
            return {
                ...state,
                user: {}
            };
            case "GET_FINDATA":
            return {
                ...state,
                financeLeads: action.payload,
            };
        default:
            return state;
    }
};

export default dataReducer;
