const initialState = {
    spinner: false,
    listloopnet: [],
    loopnetdata: {},
    financelead: {},
    total: 0,
};

const dataReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SPINNER_LOOPNET_DATA":
            return {
                ...state,
                spinner: true
            };
        case "LIST_LOOPNET_DATA":
            return {
                ...state,
                spinner: action.spinner,
                total: action.total,
                listloopnet: action.payload
            };
        case "VIEW_LOOPNET_DATA":
            return {
                ...state,
                spinner: action.spinner,
                loopnetdata: action.payload
            };
        case "VIEW_FINANCEALLLEAD_DATA":
            return {
                ...state,
                spinner: action.spinner,
                financelead: action.payload
            };

        default:
            return state;
    }
};

export default dataReducer;
