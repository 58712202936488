import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import store from './store';
import './index.css';
import axios from 'axios';
import { Error } from './Common/swal';


axios.interceptors.request.use(
  (req) => {
    const accessToken = localStorage.getItem("cornovuscapitaltoken");
    req.headers.Authorization = accessToken;
    return req;
  },
  (err) => {
    return Promise.reject(err);
  }
);

axios.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    if (err.response) {
      if (err.response.status === 403) {
        const { message } = err.response.data;
        Error(message);
        localStorage.removeItem('cornovuscapitaltoken');
        localStorage.removeItem('role');
        window.location = '/';
      }
    }
    return Promise.reject(err);
  }
);


ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);