import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { fetchHandleInputUser } from '../../Action/userAction';
import ImportedURL from '../../Common/api';
import { Error, Success } from '../../Common/swal';
import axios from 'axios';
import validator from 'validator';

const AddUser = ({ show, handleClose, modalType, id = '' }) => {

    const userReducer = useSelector(state => state.user);
    const data = userReducer.user ? userReducer.user : {};
    const dispatch = useDispatch();

    const [nameError, setNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [designationError, setDesignationError] = useState(false);
    const [savingSpinner, setSaveSpinner] = useState(false);
    const [emailValidError, setEmailValidError] = useState(false);

    const onChange = (e) => {
        const { name, value } = e.target;
        dispatch(fetchHandleInputUser(name, value));
        if (name == "name") {
            setNameError(false);
        }
        if (name == "email") {
            let emailValidErrorFlag = false;
            if (value) {
                if (!validator.isEmail(value)) {
                    emailValidErrorFlag = true;
                } else {
                    const tld = value.split('.').pop();
                    if (tld.length < 2 || tld.length > 3) {
                        emailValidErrorFlag = true;
                    }
                }
            }
            setEmailValidError(emailValidErrorFlag);
            setEmailError(false);
        }
        if (name == "password") {
            setPasswordError(false);
        }
        if (name == "designation") {
            setDesignationError(false);
        }

    }

    const submit = () => {
        let newData = { ...data }
        for (let key in newData) {
            if (newData.hasOwnProperty(key) && typeof newData[key] === 'string') {
                newData[key] = newData[key].trim();
            }
        }

        let valid = 1;
        if (!newData.name) {
            setNameError(true);
            valid = 0;
        }
        if (!newData.email) {
            setEmailError(true);
            valid = 0;
        }
        if (emailValidError) {
            valid = 0;
        }
        if (modalType == "Add" && !newData.password) {
            setPasswordError(true);
            valid = 0;
        }
        if (!newData.designation) {
            setDesignationError(true);
            valid = 0;
        }

        if (valid) {
            setSaveSpinner(true);
            if (modalType == "Add") {
                axios.post(ImportedURL.API.adduser, newData)
                    .then((res) => {
                        const { message } = res.data ? res.data : {};
                        Success(message);
                        reset();
                        handleClose(true);
                        setSaveSpinner(false);
                    }).catch(({ response }) => {
                        const { message } = response.data ? response.data : {}
                        Error(message);
                        setSaveSpinner(false);
                    });
            } else {
                axios.post(ImportedURL.API.updateuser + "/" + id, newData)
                    .then((res) => {
                        const { message } = res.data ? res.data : {};
                        Success(message);
                        reset();
                        handleClose(true);
                        setSaveSpinner(false);
                    }).catch(({ response }) => {
                        const { message } = response.data ? response.data : {}
                        Error(message);
                        setSaveSpinner(false);
                    });
            }
        }

    }

    const reset = () => {
        setNameError(false);
        setEmailError(false);
        setPasswordError(false);
        setDesignationError(false);
        setSaveSpinner(false);
        setEmailValidError(false);
    }
    const handleCloseSubmit = () => {
        handleClose();
        reset();
    }

    return (
        <>
            <Modal show={show}>
                <Modal.Header>
                    <Modal.Title>{modalType + " User"}</Modal.Title>
                    <i className="fa fa-times close_icon_modal" onClick={handleCloseSubmit}></i>
                </Modal.Header>
                <Modal.Body>
                    <div className="">
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label className="form-label">Username<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                    <input type="text" className="form-control" name="name" value={data.name ? data.name : ""} onChange={onChange} placeholder="Username" />
                                    <div className="invalid-feedback" style={{ display: nameError ? "block" : 'none' }}>Username is required</div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <label className="form-label">Email<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                    <input type="text" className="form-control" name="email" value={data.email ? data.email : ""} onChange={onChange} placeholder="Email" />
                                    <div className="invalid-feedback" style={{ display: emailError ? "block" : 'none' }}>Email is required</div>
                                    <div className="invalid-feedback" style={{ display: emailValidError ? "block" : 'none' }}>Enter valid email</div>
                                </div>
                            </div>
                            {
                                modalType == "Add" ?
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="form-label">Password<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                            <input type="text" className="form-control" name="password" value={data.password ? data.password : ""} onChange={onChange} placeholder="Password" />
                                            <div className="invalid-feedback" style={{ display: passwordError ? "block" : 'none' }}>Password is required</div>
                                        </div>
                                    </div>
                                    : ""
                            }
                            <div className="col-12">
                                <div className="form-group">
                                    <label className="form-label">Designation<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                    <input type="text" className="form-control" name="designation" value={data.designation ? data.designation : ""} onChange={onChange} placeholder="Designation" />
                                    <div className="invalid-feedback" style={{ display: designationError ? "block" : 'none' }}>Designation is required</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseSubmit}>
                        <i className="fa fa-times mr-2"></i>Close
                    </Button>
                    {savingSpinner ?
                        <Button variant="primary">
                            <i className="fa fa-spinner fa-spin mr-2"></i>{modalType == "Add" ? "Saving" : "Updating"}
                        </Button>
                        :
                        <Button variant="primary" onClick={submit}>
                            <i className="fas fa-download mr-2"></i>{modalType == "Add" ? "Save" : "Update"}
                        </Button>}
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default AddUser
