import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';

const ViewUser = ({ show, handleClose }) => {

    const userReducer = useSelector(state => state.user);
    const data = userReducer.user ? userReducer.user : {};

    return (
        <>
            <Modal show={show}>
                <Modal.Header>
                    <Modal.Title>{"View User"}</Modal.Title>
                    <i className="fa fa-times close_icon_modal" onClick={handleClose}></i>
                </Modal.Header>
                <Modal.Body>
                    <div className="">
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-3"><label className="form-label">Username</label></div>
                                        <div className="col-1"><label className="form-label">:</label></div>
                                        <div className="col-8"><span className="form-label">{data.name ? data.name : "---"}</span></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-3"><label className="form-label">Email</label></div>
                                        <div className="col-1"><label className="form-label">:</label></div>
                                        <div className="col-8"><span className="form-label">{data.email ? data.email : "---"}</span></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-3"><label className="form-label">Designation</label></div>
                                        <div className="col-1"><label className="form-label">:</label></div>
                                        <div className="col-8"><span className="form-label">{data.designation ? data.designation : "---"}</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default ViewUser
