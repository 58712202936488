import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Navbar from "./Components/Layouts/navbar";
import Sidebar from "./Components/Layouts/sidebar";
import Footer from './Components/Layouts/footer';
import ListLoopnet from './Components/Loopnet/listloopnet';
import ListFetchData from './Components/FetchData/listfetchdata';
import { fetchCityData } from './Action/citiesAction';
import ViewLoopNet from './Components/Loopnet/viewloopnet';
import Login from './Components/Authentication/login';
import ForgotPassword from './Components/Authentication/forgotpassword';
import ChangePassword from './Components/Settings/changepassword';
import ListUser from './Components/User/listuser';
import { fetchAccountDetails, fetchAccountSpinner } from './Action/accountAction';
import Notfound from './Components/Authentication/notfound';
import SpinnerComponent from './Utilities/Spinner/spinner';
import ListFinancialLead from './Components/FinancialLead/listfinanciallead';

const citylist = localStorage.getItem("citylist");
const statelist = localStorage.getItem("statelist");
const token = localStorage.getItem("cornovuscapitaltoken");

function App() {
  const accountReducer = useSelector(state => state.account);
  const account = accountReducer.account ? accountReducer.account : {};
  const spinner = accountReducer.spinner ? accountReducer.spinner : false;

  const dispatch = useDispatch();

  const onFetchData = (query = {}) => {
    if (token && (!citylist || !statelist)) {
      dispatch(fetchCityData());
    }
    if (token) {
      dispatch(fetchAccountSpinner());
      dispatch(fetchAccountDetails());
    }
  }

  useEffect(() => {
    onFetchData();
  }, []);

  return (
    <>
      <Router>
        {
          token ?
            <>
              <div className="hold-transition sidebar-mini layout-fixed">
                {
                  spinner
                    ?
                    <SpinnerComponent spinner={spinner} showFullPageSpinner={true} />
                    :
                    <div className="wrapper">
                      <Navbar />
                      <Sidebar />

                      <Routes>
                        <Route path="/" element={<ListLoopnet />} />
                        <Route path="/fetch-data" element={<ListFetchData />} />
                        <Route path="/view-loopnet/:id" element={<ViewLoopNet />} />
                        <Route path="/change-password" element={<ChangePassword />} />
                        {account.role == "admin" ? <Route path="/list-user" element={<ListUser />} /> : ""}
                        <Route path="*" element={<Notfound />} />
                        {account.role == "admin" ? <Route path="/list-financiallead" element={<ListFinancialLead/>} /> : ""}
                        <Route path="*" element={<Notfound />} />
                      </Routes>
                      <Footer />
                    </div>
                }
              </div>
            </>
            :
            <>
              <div className="hold-transition login-page">
                <Routes>
                  <Route path="/" element={<Login />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/forgot-password" element={<ForgotPassword />} />
                  <Route path="*" element={<Navigate to="/" />} />
                </Routes>
              </div>
            </>
        }

      </Router>
    </>
  );
}

export default App;
