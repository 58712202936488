import React from 'react';
import Select from 'react-select';

const MultiSelectComponent = ({ options, onChange, name, value, reference, citySelectAll }) => {
    const handleChange = (selectedOptions) => {
        if (!selectedOptions) {
            onChange({ name, value: [], selectAll: true });
            return;
        }
        let selectId = [];
        const allIncludes = selectedOptions.some((o) => (o.label === "All" && citySelectAll));
        if (allIncludes) {
            onChange({ name, value: [{ label: "All", value: 'All', name: 'state' }], selectAll: false });
        } else {
            selectId = selectedOptions.filter((item) => item.label !== "All");
            onChange({ name, value: selectId, selectAll: true });
        }
    };

    return (
        <Select
            isMulti
            options={options}
            onChange={handleChange}
            value={value}
            ref={reference}
        />
    );
};

export default MultiSelectComponent;
