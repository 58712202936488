import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import ImportedURL from '../../Common/api';
import { Error, Success } from '../../Common/swal';
import axios from 'axios';

const ChangePassword = () => {
    const [password, setPassword] = useState("");
    const [confirmpassword, setConfirmPassword] = useState("");
    const [passwordError, setPasswordError] = useState(false);
    const [confirmpasswordError1, setConfirmPasswordError1] = useState("");
    const [confirmpasswordError, setConfirmPasswordError] = useState("");
    const [passwordstatus1, setPasswordstatus1] = useState(true);
    const [passwordstatus2, setPasswordstatus2] = useState(true);
    const [saveSpinner, setSaveSpinner] = useState(false);

    const onchange = (e) => {
        const { value, name } = e.target;
        if (name == 'password') {
            setPassword(value);
            setPasswordError(false);
        }
        if (name == 'confirmpassword') {
            setConfirmPasswordError1(false);
            setConfirmPassword(value);
            if (password == value || value == "") {
                setConfirmPasswordError(false);
            } else {
                setConfirmPasswordError(true);
            }
        }

    }
    const submit = () => {
        const formData = {
            password: password ? password.trim() : "",
            confirmpassword: confirmpassword ? confirmpassword.trim() : "",
        }
        let valid = 1;
        if (!formData.password) {
            setPasswordError(true);
            valid = 0;
        }
        if (!formData.confirmpassword) {
            setConfirmPasswordError1(true);
            setConfirmPasswordError(false);
            valid = 0;
        }
        if (formData.password && confirmpasswordError) {
            valid = 0;
        }
        if (valid) {
            setSaveSpinner(true);
            axios.post(ImportedURL.API.changepassword, formData)
                .then((res) => {
                    const { message } = res.data ? res.data : {};
                    Success(message);
                    setSaveSpinner(false);
                    localStorage.setItem('cornovuscapitaltoken', "");
                    localStorage.setItem('role', "");
                    window.location.href = "/";
                }).catch(({ response }) => {
                    const { message } = response.data ? response.data : {}
                    Error(message);
                    setSaveSpinner(false);
                });
        }
    }
    return (
        <>
            <div class="content-wrapper">
                <div class="content-header">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-sm-6">
                                <h1 class="m-0">Change password</h1>
                            </div>
                            <div class="col-sm-6">
                                <ol class="breadcrumb float-sm-right">
                                    <li class="breadcrumb-item"><Link to={"/"}>Dashboard</Link></li>
                                    <li class="breadcrumb-item active">Change password</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section class="content">
                    <div class="container-fluid">
                        <div className="row">
                            <div class="col-md-6">
                                <div class="card card-primary">
                                    <form>
                                        <div class="card-body">
                                            <div class="form-group">
                                                <label className="form-label">Password<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                <div class="input-group">
                                                    <input type={passwordstatus1 ? "password" : "text"} className="form-control" name="password" value={password ? password : ''} onChange={onchange} placeholder="Password" autoComplete="nope" />
                                                    <div class="input-group-append">
                                                        <span class="input-group-text" onClick={() => setPasswordstatus1(!passwordstatus1)}>{passwordstatus1 ? <i class="fas fa-lock"></i> : <i class="fas fa-unlock"></i>}</span>
                                                    </div>
                                                </div>
                                                <div className="invalid-feedback" style={{ display: passwordError ? "block" : 'none' }}>Password is required</div>
                                            </div>
                                            <div class="form-group">
                                                <label className="form-label">Confirm Password<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                <div class="input-group">
                                                    <input type={passwordstatus2 ? "password" : "text"} className="form-control" name="confirmpassword" value={confirmpassword ? confirmpassword : ''} onChange={onchange} placeholder="Confirm Password" autoComplete="nope" />
                                                    <div class="input-group-append">
                                                        <span class="input-group-text" onClick={() => setPasswordstatus2(!passwordstatus2)}>{passwordstatus2 ? <i class="fas fa-lock"></i> : <i class="fas fa-unlock"></i>}</span>
                                                    </div>
                                                </div>
                                                <div className="invalid-feedback" style={{ display: confirmpasswordError1 ? "block" : 'none' }}>Confirm Password is required</div>
                                                <div className="invalid-feedback" style={{ display: confirmpasswordError ? "block" : 'none' }}>Password doesn't match</div>
                                            </div>
                                        </div>
                                        <div class="card-footer">
                                            {saveSpinner ?
                                                <button type="button" class="btn btn-primary"><i class="fa fa-spinner fa-spin mr-2"></i>Saving</button>
                                                :
                                                <button type="button" class="btn btn-primary" onClick={submit}><i class="fas fa-sign-in-alt mr-2"></i>Save</button>
                                            }
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section >
            </div >
        </>
    )
}

export default ChangePassword
