import { decompress } from 'lz-string';

const compressedCitylist = localStorage.getItem('citylist');
const compressedStatelist = localStorage.getItem('statelist');

const initialState = {
    spinner: false,
    initialTrigger: false,
    citylist: compressedCitylist ? JSON.parse(decompress(compressedCitylist)) : [],
    statelist: compressedStatelist ? JSON.parse(decompress(compressedStatelist)) : [],
    listfetchdata: [],
    selectcities: [],
    selectstates: [],
    total: 0,
};

const dataReducer = (state = initialState, action) => {
    switch (action.type) {
        case "CITY_LIST_DATA":
            return {
                ...state,
                citylist: action.payload,
                statelist: action.statelist,
            };
        case "LIST_FETCH_DATA":
            return {
                ...state,
                listfetchdata: action.payload,
                total: action.total,
                spinner: action.spinner,
            };
        case "FETCH_DATA_SPINNER":
            return {
                ...state,
                spinner: true
            };
        case "GET_CITY_AND_SALES":
            return {
                ...state,
                selectcities: action.payload,
                selectstates: action.statelist,
            };


        default:
            return state;
    }
};

export default dataReducer;
