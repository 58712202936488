import React from 'react'
import { Link } from 'react-router-dom';

const Notfound = () => {
    return (
        <>
            <div className="content-wrapper">
                <section class="content">
                    <div class="error-page">
                        <h2 class="headline ">404</h2>
                        <div class="error-content">
                            <h3><i class="fas fa-exclamation-triangle text-warning"></i> Oops! Page not found.</h3>
                            <p>
                                We could not find the page you were looking for.
                                Meanwhile, you may <Link to="/">return to the Dashboard</Link>.
                            </p>
                        </div>
                    </div>
                </section>

            </div>
        </>
    )
}

export default Notfound
