import phonenumberformat from './phonenumberformat.json';
import ImageNotFountObjectFit from '../Assets/Images/imagenotfound.jpg';

export function formatPhoneNumber(phoneNo, cc) {
    let phoneNumber = phoneNo ? (phoneNo).trim() : ''
    let countryCode = cc ? ((cc).trim()).slice(0, 2) : '';
    const findFormat = countryCode ? phonenumberformat.find(e => ((e.code).toLowerCase() == (countryCode).toLowerCase())) : '';
    var format = findFormat ? findFormat.format : ''
    if (format && phoneNumber && format.length >= 10) {
        const digitsOnly = phoneNumber.replace(/\D/g, '');
        let formattedNumber = '';
        let index = 0;
        for (let i = 0; i < format.length; i++) {
            if (format[i] === '#') {
                formattedNumber += digitsOnly[index] || '';
                index++;
            } else {
                formattedNumber += format[i];
            }
        }
        return formattedNumber;
    } else {
        return phoneNumber;
    }
}

export function trimDataInNumberFormat(value) {
    if (value) {
        let trimValue = value ? (value).trim() : "";
        return trimValue ? Number(trimValue) : 0
    } else {
        return 0
    }
}

export function onErrorImage(e) {
    e.target.onerror = null;
    e.target.src = ImageNotFountObjectFit;
}

export function replaceLoopnetPDFName(value = '') {
    if (value) {
        return value.trim().replace(/^, /, '').replace(/[\s,]+/g, '_')
    } else {
        return 'loopnet'
    }
}

export function loopnetCSVName(value = '') {
    const now = new Date();
    const year = now.getUTCFullYear();
    const month = String(now.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
    const date = String(now.getUTCDate()).padStart(2, '0');
    const hours = String(now.getUTCHours()).padStart(2, '0');
    const minutes = String(now.getUTCMinutes()).padStart(2, '0');
    const seconds = String(now.getUTCSeconds()).padStart(2, '0');

    const filename = `${year}_${month}_${date}_${hours}:${minutes}:${seconds}.pdf`;

    return filename;
}

export function getSortModelAlternative(columnApi) {
    const columnState = columnApi.getColumnState();
    const sortModel = columnState
        .filter(col => col.sort)
        .map(col => ({
            colId: col.colId,
            sort: col.sort,
        }));

    return sortModel;
};
