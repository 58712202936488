import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {fetchLoopnetData,fetchLoopnetDataSpinner} from "../../Action/loopnetActions";
import {getFinancialLeadList} from '../../Action/financialledAction';
import RowSelectAggridComponent from "../../Utilities/Ag-Grid/rowselectaggridcomponents";
import {formatPhoneNumber,getSortModelAlternative,loopnetCSVName,replaceLoopnetPDFName} from "../../Common/validate";
import SpinnerComponent from "../../Utilities/Spinner/spinner";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import ImportedURL from "../../Common/api";
import Select from "react-select";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Error, Success } from "../../Common/swal";
import moment, { max, min } from "moment";
import { getCityAndStates } from "../../Action/citiesAction";
import ViewLoopNet from "./viewloopnet";
import Swal from "sweetalert2";
import MultiSelectComponent from "../../Utilities/SelectOption/multiselect";

const defaultColDef = {
  editable: false,
  sortable: true,
  resizable: true,
  filter: true,
  flex: 1,
  minWidth: 100,
  filterParams: { newRowsAction: "keep" },
};

const propertyUseOptions = [
  { value: "business", label: "Business For Sale", name: "propertUse" },
  { value: "owner", label: "Vacant/Owner-User Properties", name: "propertUse" },
  { value: "investment", label: "Investment Properties", name: "propertUse" },
];

const getImageAsBase64 = async (url) => {
  try {
    if (url) {
      const response = await fetch(url);
      if (!response.ok) {
        return null;
      }
      const blob = await response.blob();
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      });
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

const ListLoopnet = () => {
  const gridApiRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const data = useSelector((state) => state.loopnet);
  const financeleaddata = useSelector((state) => state.financelead); //financelead uses rootreducer name
  const citydata = useSelector((state) => state.cities);
  const statelist = citydata.selectstates ? citydata.selectstates : [];
  const citylist = citydata.selectcities ? citydata.selectcities : [];
  const rowData = data.listloopnet ? data.listloopnet : [];
  const financeleadUser=financeleaddata.financeLeads?financeleaddata.financeLeads:[];
  const total = data.total ? data.total : 0;
  const spinner = data.spinner ? data.spinner : false;
  const initialTrigger = data.initialTrigger ? data.initialTrigger : false;
 

  const [perPage, setPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRows, setSelectedRows] = useState([]);
  const [progressbar, setProgressbar] = useState({ value: 0, max: 0 });
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [initialTriggle, setInitialTriggle] = useState(false);
  const [filter, setFilter] = useState("");
  const [sort, setSort] = useState("");
  const [tabType, setTabType] = useState("new");

  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [financelead, setfinancelead] = useState("");
  const [propertType, setPropertType] = useState([]);
  const [propertUse, setPropertUse] = useState("");
  const [minPrice, setMinPrice] = useState("500000");
  const [maxPrice, setMaxPrice] = useState("5000000");
  const [propertTypeSelectAll, setPropertTypeSelectAll] = useState(true);
  const [resetFilter, setResetFilter] = useState(false);
  const [listSpinner, setListSpinner] = useState(false);
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [sendMailSpinner, setSendMailSpinner] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [downpaymentError, setDownPaymentError] = useState(false);
  const [termofloanError, setTermOfLoanError] = useState(false);
  const [FinancialError, setFinancialError] = useState(false);
  const [interestpercentageError, setInterestPercentageError] = useState(false);
  const [saving, setSaving] = useState(false);
  const [downpayment, setDownPayment] = useState("");
  const [termofloan, setTermOfLoan] = useState("25");
  const [interestpercentage, setInterestPercentage] = useState("11");
  const [additionalcost, setAdditionalCosts] = useState("0");
  const [listingId, setListingId] = useState([]);
  const [showViewModal, setShowViewModal] = useState(false);
  const [viewData, setViewData] = useState({});
  const [isSearchClicked, setIsSearchClicked] = useState(false);

  const propertyTypeOptions = [
    { value: "office", label: "Office", name: "propertType" },
    { value: "industrial", label: "Industrial", name: "propertType" },
    { value: "retail", label: "Retail", name: "propertType" },
    { value: "restaurant", label: "Restaurant", name: "propertType" },
    { value: "shopping center", label: "Shopping Center", name: "propertType" },
    { value: "multi-family", label: "Multifamily", name: "propertType" },
    { value: "specialty", label: "Specialty", name: "propertType" },
    { value: "health care", label: "Health Care", name: "propertType" },
    { value: "hospitality", label: "Hospitality", name: "propertType" },
    {
      value: "sports & entertainment",
      label: "Sports & Entertainment",
      name: "propertType",
    },
    { value: "land", label: "Land", name: "propertType" },
    {
      value: "residential income",
      label: "Residential Income",
      name: "propertType",
    },
  ];
  const showChargeCodeAll = propertyTypeOptions.filter(
    (e) => !(propertType && propertType.some((o) => e.value == o.value))
  );
  if (
    showChargeCodeAll.length > 1 &&
    propertyTypeOptions.length != propertType.length &&
    propertTypeSelectAll
  ) {
    propertyTypeOptions.unshift({
      label: "All",
      value: "All",
      name: "propertType",
    });
  }

  const onFetchData = (query = {}) => {
    const statusPropertyType = propertType.some((o) => o.label === "All");
    const formData = {
      tabType: tabType ? tabType : undefined,
      perPage: perPage,
      currentPage: currentPage,
      filter: filter,
      sort: sort,
      propertType: statusPropertyType
        ? propertyTypeOptions.map((e) => e.value)
        : propertType.map((e) => e.value),
      propertUse: propertUse && propertUse.value ? propertUse.value : "",
      state: state && state.value ? state.value : "",
      statecode: state && state.code ? state.code : "",
      city: city && city.value ? city.value : "",
      minPrice: initialTriggle ? (minPrice ? minPrice : undefined) : undefined,
      maxPrice: initialTriggle ? (maxPrice ? maxPrice : undefined) : undefined,
      ...query,
    };
    dispatch(fetchLoopnetDataSpinner());
    dispatch(fetchLoopnetData(formData));
   
  };

//   const onFetchFinanceLeadData = () => {
//     const formData = {
//         perPage: perPage,          // Assuming perPage is defined in the component
//         currentPage: currentPage,  // Assuming currentPage is defined in the component
//         filter: filter,            // Assuming filter is defined in the component
//         sort: sort                 // Assuming sort is defined in the component
//     };
//     dispatch(fetchFinancialLeadSpinner());
//     dispatch(fetchfinancealleadData(formData));
// };

  useEffect(() => {
   // localStorage.setItem("tabType", "new");
    dispatch(getCityAndStates());
    dispatch(getFinancialLeadList());
    onFetchData();
   //onFetchFinanceLeadData();
  
  }, [dispatch, perPage, currentPage, filter, sort]);

  const download = `<button title="Download" type="button" class="btn btn-icon" data-action-type="Download" data-toggle="modal" data-target="#viewmodal"><i class="fas fa-download" data-action-type="Download"></i></button>`;
  const createDownload = `<button title="Create-PDF" type="button" class="btn btn-icon" data-action-type="Create-PDF" data-toggle="modal" data-target="#viewmodal"><i class="fa fa-plus-circle" data-action-type="Create-PDF"></i></button>`;
  const view = `<button title="View" type="button" class="btn btn-icon" data-action-type="View" data-toggle="modal" data-target="#viewmodal"><i class="fa fa-eye" data-action-type="View"></i></button>`;
  const sendMail = `<button title="SendMail" type="button" class="btn btn-icon" data-action-type="SendMail" data-toggle="modal" data-target="#viewmodal"><i class="fa fa-paper-plane" data-action-type="SendMail"></i></button>`;
  const spinnerIcon = `<button title="SpinnerIcon" type="button" class="btn btn-icon" data-action-type="SpinnerIcon" data-toggle="modal" data-target="#viewmodal"><i class="fa fa-spinner fa-spin" data-action-type="SpinnerIcon"></i></button>`;
  const reject = `<button title="Reject" type="button" class="btn btn-icon" data-action-type="Reject" data-toggle="modal" data-target="#viewmodal"><i class="fa fa-ban" data-action-type="Reject"></i></button>`;
  const fetchData = `<button title="FetchData" type="button" class="btn btn-icon" data-action-type="FetchData" data-toggle="modal" data-target="#viewmodal"><i class="fas fa-sync-alt" data-action-type="FetchData"></i></button>`;
  const restore = `<button title="Restore" type="button" class="btn btn-icon" data-action-type="Restore" data-toggle="modal" data-target="#viewmodal"><i class="fas fa-redo-alt" data-action-type="Restore"></i></button>`;

  if (rowData && rowData.length > 0) {
    rowData.forEach((object, i) => {
      const actions = sendMailSpinner.includes(object._id)
        ? spinnerIcon
        : sendMail;
      object.actions =
        (tabType == "new" || tabType == "downloadpdf" ||tabType ==="credownload" || tabType==="landdownload"||tabType==="businessdownload"||tabType==="multifamilydownload"? createDownload : "") +
        (object.pdf ? download : "") +
        (tabType == "new" ? reject : "") +
        (["reject", "business", "cre","land","multifamily"].includes(tabType) ? restore : "") +
        view +
        (!["reject", "business", "cre","land","multifamily"].includes(tabType) ? fetchData : "");
    });
  }

  const cityOptions = [];
  const stateOptions = [];
  if (statelist && statelist.length > 0) {
    statelist.map((item) => {
      stateOptions.push({
        value: item.statename,
        label: item.statename,
        code: item.statecode,
        name: "state",
      });
    });
  }
  if (citylist && citylist.length > 0) {
    citylist.map((item) => {
      if (state && state.value == item.statename) {
        cityOptions.push({
          value: item.cityname,
          label: item.cityname,
          name: "city",
        });
      }
    });
  }
  if (city && city.value) {
    cityOptions.unshift({ label: "Deselect", value: "deselect", name: "city" });
  }

  // financial lead 
const fincialleadOptions = [];
if (financeleadUser && Array.isArray(financeleadUser) && financeleadUser.length > 0) {
    financeleadUser.forEach((item) => {
        fincialleadOptions.push({
            value: item._id,
            label: item.name,
            name: "financelead",
        });
    });
}

// Add a default option at the beginning if 'financelead' variable has a value
if (financelead && financelead.value) {
    fincialleadOptions.unshift({ label: "Select...", value: "Deselect", name: 'financelead' });
}

   
 
  const [columnDefs] = useState([
    {
      headerName: "_id",
      field: "_id",
      hide: true,
      valueGetter: function (params) {
        return `${params.data ? params.data._id : ""}`;
      },
    },
    {
      headerName: "Status",
      field: "status-csv",
      hide: true,
      valueGetter: function (params) {
        return "Subscribed";
      },
    },
    {
      headerName: "Label",
      field: "label-csv",
      hide: true,
      valueGetter: function (params) {
        return "Broker";
      },
    },
    // {
    //   headerName: "State",
    //   field: "state-csv",
    //   hide: true,
    //   valueGetter: function (params) {
    //     return `${params.data ? params.data.state : ""}`;
    //   },
    // },
    {
      headerName: "City",
      field: "city-csv",
      hide: true,
      valueGetter: function (params) {
        return params.data && params.data.state && params.data.state.includes(",")
          ? params.data.state.split(",")[0].trim()
          : "";
      },
    },
    {
      headerName: "State",
      field: "state-csv",
      hide: true,
      valueGetter: function (params) {
        return params.data && params.data.state && params.data.state.includes(",")
          ? params.data.state.split(",")[1].trim()
          : "";
      },
    },
    
    {
      headerName: "Broker",
      field: "name-csv",
      hide: true,
      valueGetter: function (params) {
        return `${params.data ? params.data.brokername : ""}`;
      },
    },
    {
      headerName: "Brokerage",
      field: "brokerage-csv",
      hide: true,
      valueGetter: function (params) {
        return `${params.data ? params.data.brokercompany : ""}`;
      },
    },
    {
      headerName: "Phone",
      field: "phone-csv",
      hide: true,
      valueGetter: function (params) {
        return `${
          params.data
            ? formatPhoneNumber(
                params.data.brokerphonenumber,
                params.data.brokercccode
              )
            : ""
        }`;
      },
    },
    {
      headerName: "Email",
      field: "email-csv",
      hide: true,
      valueGetter: function (params) {
        return `${params.data ? params.data.brokeremail : ""}`;
      },
    },
    {
      headerName: "Address",
      field: "address",
      sortable: false,
      floatingFilter: true,
      headerCheckboxSelection: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
      valueGetter: function (params) {
        if (params.data && params.data.address)
          return `${params.data ? params.data.address : ""}`;
        else return "---";
      },
    },
    {
      headerName: "Price",
      field: "price",
      sortable: true,
      floatingFilter: true,
      valueGetter: function (params) {
        if (params.data && params.data.price) return Number(params.data.price);
        else return "---";
      },
    },
    {
      headerName: "Square Feet",
      field: "squarefeet",
      sortable: false,
      floatingFilter: true,
      valueGetter: function (params) {
        if (params.data && params.data.squarefeet)
          return `${params.data ? params.data.squarefeet : ""}`;
        else return "---";
      },
    },
    {
      headerName: "Property Types",
      field: "propertytypes",
      sortable: true,
      floatingFilter: true,
      valueGetter: function (params) {
        return `${
          params.data &&
          params.data.PropertyFacts &&
          params.data.PropertyFacts.PropertyType
            ? params.data.PropertyFacts.PropertyType
            // :params.data &&
            // params.data.PropertyFacts &&
            // params.data.PropertyFacts.propertyType? params.data.PropertyFacts.propertyType
            : "---"
        }`;
      },
    },
    {
      headerName: "Name | Brokerage",
      field: "brokername",
      sortable: true,
      floatingFilter: true,
      valueGetter: function (params) {
        if (params.data && params.data.brokername)
          return `${params.data ? params.data.brokername : ""} | ${
            params.data ? params.data.brokercompany : ""
          }`;
        else return "---";
      },
    },
    {
      headerName: "Phone | Email",
      field: "brokerphonenumber",
      sortable: false,
      floatingFilter: true,
      valueGetter: function (params) {
        if (params.data && params.data.brokerphonenumber)
          return `${params.data ? params.data.brokerphonenumber : ""} | ${
            params.data ? params.data.brokeremail : ""
          }`;
        else return "---";
      },
      cellRenderer: function (params) {
        if (params.data && params.data.brokerphonenumber)
          return `${
            params.data
              ? formatPhoneNumber(
                  params.data.brokerphonenumber,
                  params.data.brokercccode
                )
              : ""
          } | ${params.data ? params.data.brokeremail : ""}`;
        else return "---";
      },
    },
    {
      headerName: "Created Date & Time",
      field: "createdAt",
      sortable: true,
      floatingFilter: true,
      valueGetter: function (params) {
        return params.data && params.data.createdAt
          ? `${moment(params.data.createdAt).format("MM-DD-YYYY hh:mm A")}`
          : "---";
      },
    },
    {
      headerName: "Download By",
      field: "downloadpdfuser",
      hide: true,
      sortable: true,
      floatingFilter: true,
      valueGetter: function (params) {
        return params.data && params.data.downloadpdfuser
          ? params.data.downloadpdfuser
          : "---";
      },
    },
    {
      headerName: "Rejected By",
      field: "rejectusername",
      hide: true,
      sortable: true,
      floatingFilter: true,
      valueGetter: function (params) {
        return params.data && params.data.rejectusername
          ? params.data.rejectusername
          : "---";
      },
    },
    {
      headerName: "Modified By",
      field: "modifiedby",
      hide: true,
      sortable: true,
      floatingFilter: true,
      valueGetter: function (params) {
        return params.data && params.data.rejectusername
          ? params.data.rejectusername
          : "---";
      },
    },
    {
      headerName: "Actions",
      field: "actions",
      cellStyle: { "text-align": "center" },
      pinned: "right",
      filter: false,
      suppressMenu: true,
      sortable: false,
      floatingFilter: false,
      cellRenderer: function (params) {
        return <div dangerouslySetInnerHTML={{ __html: params.value }}></div>;
      },
      // tooltipValueGetter: (params) => {
      //     return (params.data && params.data.downloadpdfuser && params.data.downloadpdfuser.length > 0) ? (params.data.downloadpdfuser.map(e => e.username)).join(', ') : null;
      // }
    },
  ]);

  const onChangeTab = (type) => {
    let updatedColumnDefs = columnDefs;
    updatedColumnDefs = updatedColumnDefs.map((col) => {
      if (col.field === "rejectusername") {
        return { ...col, hide: type == "reject" ? false : true };
      }
      if (col.field === "modifiedby") {
        return {
          ...col,
          hide: type === "cre" || type === "business" ||type ==="multifamily" || type==="land" ? false : true,
        };
      }
      if (col.field === "downloadpdfuser") {
        return { ...col, hide: type == "downloadpdf"||type ==="credownload" || type==="landdownload"||type==="businessdownload"||type==="multifamilydownload" ? false : true };
      }
      return col;
    });
    gridApi.setColumnDefs(updatedColumnDefs);
    localStorage.setItem("tabType", type ? type : undefined);
    setPerPage(25);
    setCurrentPage(1);
    if (gridApiRef.current) {
      const columnApi = gridApiRef.current.columnApi;
      const columnState = columnApi.getColumnState();
      columnState.forEach((col) => {
        col.sort = null;
        col.sortIndex = null;
      });
      columnApi.applyColumnState({ state: columnState, applyOrder: true });
      gridApiRef.current.api.setFilterModel("");
      setFilter("");
      setSort("");
    }
    setTabType(type);
    onFetchData({
      tabType: type ? type : undefined,
      perPage: 25,
      currentPage: 1,
      filter: "",
      sort: "",
    });
  };

  const onFilterChanged = (event) => {
    const newFilter = event.api.getFilterModel();
    setCurrentPage(1);
    setFilter(newFilter);
    onFetchData({
      currentPage: 1,
      filter: newFilter,
    });
  };

  const onSortChanged = (event) => {
    const sortModel = getSortModelAlternative(event.columnApi);
    setCurrentPage(1);
    setSort(sortModel);
    onFetchData({
      currentPage: 1,
      sort: sortModel,
    });
  };

  const handleDownload = () => {
    if (isSearchClicked) {
     // console.log("Search button was clicked.");
      setResetFilter(true);
      setIsSearchClicked(true)
      
    } else {
      //console.log("Search button was not clicked.");
      setResetFilter(false);
      setIsSearchClicked(false);
    }
    setShowModal(true);
  };
  const handleCloseModal = () => {
    reset();
    if (isSearchClicked) {
      //console.log("Search button was clicked.");
      setResetFilter(true);
      setIsSearchClicked(true)
      
    } else {
     // console.log("Search button was not clicked.");
      setResetFilter(false);
      setIsSearchClicked(false);
    }
    setShowModal(false);
  };
  const handleCloseViewModal = () => {
    setShowViewModal(false);
  };
  const onChangeStatus = (_id, ListingId, type) => {
    setShowViewModal(false);
    onFetchReject(_id, ListingId, type);
  };
  const onFetchReject = (_id, ListingId, type = "reject") => {
    let confirmationMessage;
    switch(type) {
        case 'cre':
            confirmationMessage = "Are you sure you want to mark as CRE?";
            break;
        case 'business':
            confirmationMessage = "Are you sure you want to mark as Business?";
            break;
        case 'reject':
          confirmationMessage = "Are you sure you want to mark as Reject?";
          break;
          case 'multifamily':
            confirmationMessage = "Are you sure you want to mark as Multifamily?";
            break;
            case 'land':
              confirmationMessage = "Are you sure you want to mark as Land?";
              break;
        default:
            confirmationMessage = "Are you sure you want to mark as Reject?";
            break;
    }
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-primary ",
        cancelButton: "btn btn-default mr-3",
      },
      buttonsStyling: false,
    });
    swalWithBootstrapButtons
      .fire({
        title: confirmationMessage,
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          setListSpinner(true);
          axios
            .post(ImportedURL.API.updaterejectstatus, {
              _id: _id,
              status: true,
              type: type,
              ListingId: ListingId,
            })
            .then((res) => {
              onFetchData();
              let successMessage=""
              switch (type) {
                case 'reject':
                    successMessage = "Rejected status updated successfully";
                    break;
                case 'cre':
                    successMessage = "CRE status updated successfully";
                    break;
                case 'business':
                    successMessage = "Business status updated successfully";
                    break;
                case 'multifamily':
                      successMessage = "Multifamily status updated successfully";
                      break;
                case 'land':
                      successMessage = "Land status updated successfully";
                      break;
                default:
                    successMessage = `${type} status updated successfully`;
            }
            Success(successMessage);
              setListSpinner(false);
            })
            .catch((err) => {
              setListSpinner(false);
            });
        }
      });
  };
  const onRowClicked = async (event) => {
    const { _id, ListingId, image, address } = event.data;
    const value = event.event.target.getAttribute("data-action-type");
    if (value === "Create-PDF") {
      if (isSearchClicked) {
      //  console.log("Search button was clicked.");
        setResetFilter(true);
        setIsSearchClicked(true)
        
      } else {
       // console.log("Search button was not clicked.");
        setResetFilter(false);
        setIsSearchClicked(false);
      }
      setListingId([{ _id: _id, image: image, address: address }]);
      handleDownload();
      setSelectedRows([]);
      if (gridApiRef.current) {
        const selectedNodes = gridApiRef.current.api.getSelectedNodes();
        selectedNodes.forEach((node) => node.setSelected(false));
      }
    }
    if (value === "View") {
      setViewData(event.data);
      setShowViewModal(true);
    }
    if (value === "Download") {
      if (isSearchClicked) {
       // console.log("Search button was clicked.");
        setResetFilter(true);
        setIsSearchClicked(true)
        
      } else {
      //  console.log("Search button was not clicked.");
        setResetFilter(false);
        setIsSearchClicked(false);
      }
      let base64 = await getImageAsBase64(image);
      const formData = {
        id: event.data._id ? event.data._id : null,
        downpayment: event.data.downpayment ? event.data.downpayment : "",
        financelead: financelead ? financelead : "",
        termofloan: event.data.termofloan ? event.data.termofloan : "",
        additionalcost: event.data.additionalcost
          ? event.data.additionalcost
          : "",
        interestpercentage: event.data.interestpercentage
          ? event.data.interestpercentage
          : "",
        alreadexists: true,
        imageBase64: base64,
        tabType:tabType
      };
      
      setListSpinner(true);
      axios
        .post(ImportedURL.API.downloadPdf, formData, { responseType: "blob" })
        .then((res) => {
          const getData = rowData.find((e) => e._id == formData.id);
          let formattedAddress = replaceLoopnetPDFName(getData.address);
          const blob = new Blob([res.data], { type: "application/pdf" });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = formattedAddress + ".pdf";
          link.click();
          link.remove();
          window.URL.revokeObjectURL(link.href);
          Success("PDF download successfully");
          setListSpinner(false);
        })
        .catch((err) => {
          setListSpinner(false);
        });
    }
    if (value == "SendMail") {
      setListSpinner(true);
      setSendMailSpinner((prevState) => [...prevState, _id]);
      let base64 = await getImageAsBase64(image);
      const formData = {
        id: event.data._id ? event.data._id : null,
        downpayment: event.data.downpayment ? event.data.downpayment : "",
        financelead: financelead ? financelead : "",
        termofloan: event.data.termofloan ? event.data.termofloan : "",
        additionalcost: event.data.additionalcost
          ? event.data.additionalcost
          : "",
        interestpercentage: event.data.interestpercentage
          ? event.data.interestpercentage
          : "",
        imageBase64: base64,
      };
      axios
        .post(ImportedURL.API.sendmailwithpdf, formData)
        .then((res) => {
          setSendMailSpinner((prevState) => prevState.filter((e) => e !== _id));
          Success("Send email successfully");
          setListSpinner(false);
        })
        .catch((err) => {
          setSendMailSpinner((prevState) => prevState.filter((e) => e !== _id));
          setListSpinner(false);
        });
    }
    if (value == "Reject") {
      onFetchReject(_id, ListingId);
    }
    if (value == "FetchData") {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-primary ",
          cancelButton: "btn btn-default mr-3",
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons
        .fire({
          title: "Are you fetching the data again?",
          text: "",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            setListSpinner(true);
            axios
              .post(ImportedURL.API.updateloopnetdata, {
                _id: _id,
                ListingId: ListingId,
              })
              .then((res) => {
                onFetchData();
                Success("Data fetched successfully");
                setListSpinner(false);
              })
              .catch(({ response }) => {
                const { message } = response.data ? response.data : {};
                Error(message);
                setListSpinner(false);
              });
          }
        });
    }
    if (value == "Restore") {
      let alertTilte = "";
      if (tabType == "reject") {
        alertTilte = "Are you sure you want to unmark from Rejected?";
      }
      else if (tabType == "cre") {
        alertTilte = "Are you sure you want to unmark from CRE?";
      } 
      else if (tabType == "business") {
        alertTilte = "Are you sure you want to unmark from Business?";
     }
     else if (tabType == "multifamily") {
      alertTilte = "Are you sure you want to unmark from Multifamily?";
   }
   else if (tabType == "land") {
    alertTilte = "Are you sure you want to unmark from Land?";
  }
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-primary ",
          cancelButton: "btn btn-default mr-3",
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons
        .fire({
          title: alertTilte,
          text: "",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            setListSpinner(true);
            axios
              .post(ImportedURL.API.updaterejectstatus, {
                _id: _id,
                status: false,
                ListingId: ListingId,
                type: tabType,
              })
              .then((res) => {
                onFetchData();
                let successMessage = "";
                switch (tabType) {
                    case 'reject':
                        successMessage = "Rejected status updated successfully";
                        break;
                    case 'cre':
                        successMessage = "CRE status updated successfully";
                        break;
                    case 'business':
                        successMessage = "Business status updated successfully";
                        break;
                    case 'multifamily':
                          successMessage = "Multifamily status updated successfully";
                          break;
                    case 'land':
                            successMessage = "Land status updated successfully";
                            break;
                    default:
                        successMessage = `${tabType} status updated successfully`;
                }
                Success(successMessage);

                setListSpinner(false);
              })
              .catch((err) => {
                setListSpinner(false);
              });
          }
        });
    }
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const onSelectionChanged = (event) => {
    const selectedRows = event.api.getSelectedRows();
    setSelectedRows(selectedRows);
  };

  const onPagination = (page) => {
    setCurrentPage(page);
    onFetchData({ currentPage: page });
  };

  const onPerPageChange = (num) => {
    setCurrentPage(1);
    setPerPage(num);
    onFetchData({ currentPage: 1, perPage: num });
  };

  const downloadPdf = () => {
    let ids = selectedRows.map((item) => ({
      _id: item._id,
      image: item.image,
      address: item.address,
    }));
    setListingId(ids);
    handleDownload();
  };

  const getRowStyle = (params) => {
    if (params.data && params.data.generatorcsvstatus) {
      return { backgroundColor: "#d7d7d7" }; // Your desired style
    }
  };

  const exportToCSV = () => {
    if (!gridApi) return;
    setListSpinner(true);

    const selectedRows = gridApi.getSelectedRows();
    const isSelected = selectedRows && selectedRows.length > 0;
    const firstRow = gridApi.getFirstDisplayedRow();
    const lastRow = gridApi.getLastDisplayedRow();

    let ids = [];
    let listingIds = [];
    if (isSelected) {
      selectedRows.map((row) => {
        ids.push(row._id);
        listingIds.push(row.ListingId);
      });
    } else {
      gridApi.forEachNodeAfterFilterAndSort((node) => {
        ids.push(node.data._id);
        listingIds.push(node.data.ListingId);
      });
    }
    axios
      .post(ImportedURL.API.updatedownloadcsvstatus, {
        ids: ids,
        listingIds: listingIds,
      })
      .then((res) => {
        onFetchData();
        gridApi.exportDataAsCsv({
          columnKeys: [
            "status-csv",
            "label-csv",
            "city-csv",  // Check that these match your grid configuration IDs
            "state-csv",
            "name-csv",
            "brokerage-csv",
            "phone-csv",
            "email-csv",
          ],       
          fileName: loopnetCSVName() + ".csv",
          shouldRowBeSkipped: (params) => {
            return isSelected
              ? !selectedRows.includes(params.node.data)
              : params.node.rowIndex < firstRow ||
                  params.node.rowIndex > lastRow;
          },
          processCellCallback: function (params) {
            return params.value;
          },
            
        });
        if (isSelected) {
          setSelectedRows([]);
          if (gridApiRef.current) {
            const selectedNodes = gridApiRef.current.api.getSelectedNodes();
            selectedNodes.forEach((node) => node.setSelected(false));
          }
        }
        setListSpinner(false);
      })
      .catch((err) => {
        setListSpinner(false);
      });
  };

  
  const onChange = (event) => {
    const { value, name } = event.target;
    const number = value
      .replace(/[^0-9.]/g, "")
      .replace(/^(\d+\.\d{0,2})\d*$/, "$1");
    const minmax = value
      .replace(/[^0-9]/g, "")
      .replace(/^(\d+\.\d{0,2})\d*$/, "$1");
    if (name == "downpayment") {
      setDownPayment(number);
      setDownPaymentError(false);
    }
    if (name == "termofloan") {
      setTermOfLoan(number);
      setTermOfLoanError(false);
    }
    if (name == "interestpercentage") {
      setInterestPercentage(number);
      setInterestPercentageError(false);
    }
    if (name == "additionalcost") {
      setAdditionalCosts(number);
    }
    if (name == "maxPrice") {
      setMaxPrice(minmax);
      if (minmax) setResetFilter(true);
    }
    if (name == "minPrice") {
      setMinPrice(minmax);
      if (minmax) setResetFilter(true);
    }
  };

  const onChangeSelect = (event) => {
    const { name, value, selectAll } = event;
    setResetFilter(true);
    if (name == "propertUse") {
      setPropertUse(event);
    }
    if (name == "propertType") {
      setPropertTypeSelectAll(selectAll);
      setPropertType(value ? value : []);
    }
    if (name == "state") {
      setState(event);
      setCity("");
    }
    if (name == "city") {
      if (value && value == "deselect") {
        setCity("");
      } else {
        setCity(event);
      }
    }
    if (name === "financelead") {
      if (value === "Deselect") {  // Ensure correct comparison for 'Deselect' (case-sensitive)
         setfinancelead("");
        setFinancialError(true);   // Show the error when 'Deselect' is selected
      } else if (value && value !== "Deselect") {
       setfinancelead(event);     // Set the selected value
        setFinancialError(false);  // Clear the error
      }
    }
  };

  const handleSubmit = async () => {
    if (isSearchClicked) {
     // console.log("Search button was clicked.");
      setResetFilter(true);
      setIsSearchClicked(true)
      
    } else {
     // console.log("Search button was not clicked.");
      setResetFilter(false);
      setIsSearchClicked(false);
    }
    let valid = 1;
    if (!downpayment) {
      setDownPaymentError(true);
      valid = 0;
    }
    if (!termofloan) {
      setTermOfLoanError(true);
      valid = 0;
    }
    if (!financelead) {
      setFinancialError(true);
      valid = 0;
    }
    if (!interestpercentage) {
      setInterestPercentageError(true);
      valid = 0;
    }
    if (valid) {
      if (listingId && listingId.length === 1) {
        setSaving(true);
        let base64 = await getImageAsBase64(
          listingId[0] && listingId[0].image ? listingId[0].image : null
        );
        const formData = {
          id: listingId[0] && listingId[0]._id ? listingId[0]._id : null,
          downpayment: downpayment ? downpayment.trim() : "",
          financelead: financelead ? financelead : "",
          termofloan: termofloan ? termofloan.trim() : "",
          interestpercentage: interestpercentage
            ? interestpercentage.trim()
            : "",
          additionalcost: additionalcost ? additionalcost.trim() : "",
          imageBase64: base64,
         tabType:tabType
        };
        axios
          .post(ImportedURL.API.downloadPdf, formData, { responseType: "blob" })
          .then((res) => {
            const getData = rowData.find((e) => e._id == formData.id);
            let formattedAddress = replaceLoopnetPDFName(getData.address);
            const blob = new Blob([res.data], { type: "application/pdf" });
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = formattedAddress + ".pdf";
            link.click();
            link.remove();
            window.URL.revokeObjectURL(link.href);
            onFetchData();
            Success("PDF download successfully");
            reset();
            // if (isSearchClicked) {
            //   console.log("Search button was clicked.");
            //   setResetFilter(true);
            //   setIsSearchClicked(true)
              
            // } else {
            //   console.log("Search button was not clicked.");
            //   setResetFilter(false);
            //   setIsSearchClicked(false);
            // }
            setShowModal(false);
            setSaving(false);
          })
          .catch(({ response }) => {
            const { message } = response.data ? response.data : {};
            Error(message);
            setSaving(false);
          });
      } else {
        reset();
        setShowModal(false);
        setSelectedRows([]);
        if (gridApiRef.current) {
          const selectedNodes = gridApiRef.current.api.getSelectedNodes();
          selectedNodes.forEach((node) => node.setSelected(false));
        }
        setShowProgressBar(true);
        setProgressbar({ value: 0, max: listingId.length });

        const downloadPdf = async (item, index) => {
          let base64 = await getImageAsBase64(item.image);
          const formData = {
            id: item._id || null,
            downpayment: downpayment ? downpayment.trim() : "",
            financelead: financelead ? financelead : "",
            termofloan: termofloan ? termofloan.trim() : "",
            interestpercentage: interestpercentage
              ? interestpercentage.trim()
              : "",
            additionalcost: additionalcost ? additionalcost.trim() : "",
            imageBase64: base64,
            tabType:tabType
          };

          try {
            const res = await axios.post(
              ImportedURL.API.downloadPdf,
              formData,
              { responseType: "blob" }
            );
            let formattedAddress = replaceLoopnetPDFName(item.address);
            const blob = new Blob([res.data], { type: "application/pdf" });
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = formattedAddress + ".pdf";
            link.click();
            link.remove();
            window.URL.revokeObjectURL(link.href);
            setProgressbar((prev) => ({
              value: prev.value + 1,
              max: prev.max,
            }));
          } catch (err) {}
        };
        const downloadAllPdfs = async () => {
          for (let i = 0; i < listingId.length; i++) {
            await downloadPdf(listingId[i], i);
          }
          Success("PDFs downloaded successfully");
          setTimeout(() => {
            let currentTabType = localStorage.getItem("tabType");
            setShowProgressBar(false);
            onFetchData({
              tabType: currentTabType ? currentTabType : tabType,
            });
          }, 2000);
        };
        downloadAllPdfs();
      }
    }
  };

  const reset = () => {
    setDownPayment("");
    setTermOfLoan("25");
    setAdditionalCosts("0");
    setInterestPercentage("11");
    setDownPaymentError(false);
    setTermOfLoanError(false);
    setInterestPercentageError(false);
    setfinancelead(false);
   
  };

  
  

  const EmptyFilter = () => {
   // console.log("empty filter is called");
    setPropertType([]);
    setPropertUse("");
    setMinPrice("500000");
    setMaxPrice("5000000");
    setIsSearchClicked(false);
    setState("");
    setCity("");
    setResetFilter(false);
    setInitialTriggle(false);
    setCurrentPage(1);
    onFetchData({
      currentPage: 1,
      propertType: [],
      propertUse: "",
      state: "",
      statecode: "",
      city: "",
      minPrice: undefined,
      maxPrice: undefined,
    });
  };

  const onSearch = () => {
    setIsSearchClicked(true); // Track that the search button was clicked
    setResetFilter(true);
    setInitialTriggle(true);
    setCurrentPage(1);
    onFetchData({
      currentPage: 1,
      minPrice: minPrice ? minPrice : undefined,
      maxPrice: maxPrice ? maxPrice : undefined,
    });
  };

  return (
    <>
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h1>Dashboard</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to={"/"}>Dashboard</Link>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div class="card-header">
                    <h3 class="card-title">List Loopnet</h3>
                    <div class="card-tools d-flex">
                      {rowData && rowData.length > 0 ? (
                        <>
                          {selectedRows && selectedRows.length > 0 ? (
                            <button
                              type="button"
                              className="btn btn-primary loopnet_header_button"
                              onClick={exportToCSV}
                            >
                              <i
                                className="fa fa-file-excel mr-2"
                                aria-hidden="true"
                              ></i>
                              Export CSV
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="btn btn-primary loopnet_header_button disabled"
                            >
                              <i
                                className="fa fa-file-excel mr-2"
                                aria-hidden="true"
                              ></i>
                              Export CSV
                            </button>
                          )}
                          
                          {tabType != "reject" ? (
                            selectedRows && selectedRows.length > 0 ? (
                              <button
                                type="button"
                                className="btn btn-primary loopnet_header_button ml-3"
                                onClick={downloadPdf}
                              >
                                <i className="fas fa-download mr-2"></i>Download
                                PDF
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="btn btn-primary loopnet_header_button ml-3 disabled"
                              >
                                <i className="fas fa-download mr-2"></i>Download
                                PDF
                              </button>
                            )
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        ""
                      )}
                      {showProgressBar ? (
                        <div
                          style={{
                            width: "60px",
                            height: "60px",
                            marginLeft: "20px",
                          }}
                        >
                          <CircularProgressbar
                            value={(progressbar.value / progressbar.max) * 100}
                            text={`${progressbar.value}/${progressbar.max}`}
                            styles={buildStyles({
                              textColor: "#007BFF",
                              pathColor: "#007BFF",
                              trailColor: "#d6d6d6",
                            })}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-3">
                        <div className="form-group">
                          <label className="form-label">State</label>
                          <Select
                            value={state}
                            onChange={onChangeSelect}
                            options={stateOptions}
                          />
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="form-group">
                          <label className="form-label">City</label>
                          <Select
                            value={city ? city : ""}
                            onChange={onChangeSelect}
                            options={cityOptions}
                          />
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="form-group">
                          <label className="form-label">Property Types</label>
                          {/* <Select
                                                        value={propertType}
                                                        onChange={onChangeSelect}
                                                        options={propertyTypeOptions}
                                                    /> */}
                          <MultiSelectComponent
                            name={"propertType"}
                            options={propertyTypeOptions}
                            value={propertType}
                            citySelectAll={propertTypeSelectAll}
                            onChange={onChangeSelect}
                          />
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="form-group">
                          <label className="form-label">Property Use</label>
                          <Select
                            value={propertUse}
                            onChange={onChangeSelect}
                            options={propertyUseOptions}
                          />
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="form-group">
                          <label className="form-label">Price Range</label>
                          <div className="price-range-loopnet">
                            <input
                              type="text"
                              className="form-control min"
                              name="minPrice"
                              value={minPrice}
                              onChange={onChange}
                              placeholder="Min $"
                              autoComplete="nope"
                            />
                            <input
                              type="text"
                              className="form-control max"
                              name="maxPrice"
                              value={maxPrice}
                              onChange={onChange}
                              placeholder="Max $"
                              autoComplete="nope"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-3 d-flex align-items-end">
                        <div className="form-group">
                          <button
                            type="button"
                            className="btn btn-primary mr-2"
                            onClick={onSearch}
                          >
                            <i className="fa fa-search mr-2"></i>Search
                          </button>
                          {resetFilter ? (
                            <button
                              type="button"
                              className="btn btn-default"
                              onClick={EmptyFilter}
                            >
                              <i className="fa fa-times-circle mr-2"></i>Reset
                            </button>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="col-12" >
                        <div
                         class="card card-primary card-outline card-outline-tabs"
                        //  className="card card-primary card-outline card-tabs"
                         >
                          <div class="card-header p-0 border-bottom-0">
                            <ul
                              class="nav nav-tabs"
                              id="custom-tabs-four-tab"
                              role="tablist"
                            >
                              <li class="nav-item">
                                <a
                                  class="nav-link active"
                                  onClick={() => onChangeTab("new")}
                                  id="custom-tabs-four-home-tab"
                                  data-toggle="pill"
                                  href="#custom-tabs-four-home"
                                  role="tab"
                                  aria-controls="custom-tabs-four-home"
                                  aria-selected="false"
                                >
                                  New
                                </a>
                              </li>
                              {/* <li class="nav-item">
                                                                <a class="nav-link" onClick={() => onChangeTab("exportcsv")} id="custom-tabs-four-tab" data-toggle="pill" href="#custom-tabs-four" role="tab" aria-controls="custom-tabs-four" aria-selected="false">Export CSV</a>
                                                            </li> */}
                              <li class="nav-item">
                                <a
                                  class="nav-link"
                                  onClick={() => onChangeTab("downloadpdf")}
                                  id="custom-tabs-four-profile-tab"
                                  data-toggle="pill"
                                  href="#custom-tabs-four-profile"
                                  role="tab"
                                  aria-controls="custom-tabs-four-profile"
                                  aria-selected="false"
                                >
                                 New Download 
                                </a>
                              </li>
                              <li class="nav-item">
                                <a
                                  class="nav-link"
                                  onClick={() => onChangeTab("reject")}
                                  id="custom-tabs-four-messages-tab"
                                  data-toggle="pill"
                                  href="#custom-tabs-four-messages"
                                  role="tab"
                                  aria-controls="custom-tabs-four-messages"
                                  aria-selected="false"
                                >
                                  Reject
                                </a>
                              </li>
                              <li class="nav-item">
                                <a
                                  class="nav-link"
                                  onClick={() => onChangeTab("cre")}
                                  id="custom-tabs-four-messages-tab"
                                  data-toggle="pill"
                                  href="#custom-tabs-four-messages"
                                  role="tab"
                                  aria-controls="custom-tabs-four-messages"
                                  aria-selected="false"
                                >
                                  CRE
                                </a>
                              </li>
                              <li class="nav-item">
                                <a
                                  class="nav-link"
                                  onClick={() => onChangeTab("credownload")}
                                  id="custom-tabs-four-messages-tab"
                                  data-toggle="pill"
                                  href="#custom-tabs-four-messages"
                                  role="tab"
                                  aria-controls="custom-tabs-four-messages"
                                  aria-selected="false"
                                >
                                  CRE Download
                                </a>
                              </li>
                              <li class="nav-item">
                                <a
                                  class="nav-link"
                                  onClick={() => onChangeTab("business")}
                                  id="custom-tabs-four-messages-tab"
                                  data-toggle="pill"
                                  href="#custom-tabs-four-messages"
                                  role="tab"
                                  aria-controls="custom-tabs-four-messages"
                                  aria-selected="false"
                                >
                                  Business
                                </a>
                              </li>
                              <li class="nav-item">
                                <a
                                  class="nav-link"
                                  onClick={() => onChangeTab("businessdownload")}
                                  id="custom-tabs-four-messages-tab"
                                  data-toggle="pill"
                                  href="#custom-tabs-four-messages"
                                  role="tab"
                                  aria-controls="custom-tabs-four-messages"
                                  aria-selected="false"
                                >
                                  Business Download
                                </a>
                              </li>
                              <li class="nav-item">
                                <a
                                  class="nav-link"
                                  onClick={() => onChangeTab("multifamily")}
                                  id="custom-tabs-four-messages-tab"
                                  data-toggle="pill"
                                  href="#custom-tabs-four-messages"
                                  role="tab"
                                  aria-controls="custom-tabs-four-messages"
                                  aria-selected="false"
                                >
                                  Multifamily
                                </a>
                              </li>
                              <li class="nav-item">
                                <a
                                  class="nav-link"
                                  onClick={() => onChangeTab("multifamilydownload")}
                                  id="custom-tabs-four-messages-tab"
                                  data-toggle="pill"
                                  href="#custom-tabs-four-messages"
                                  role="tab"
                                  aria-controls="custom-tabs-four-messages"
                                  aria-selected="false"
                                >
                                  Multifamily Download
                                </a>
                              </li>
                              <li class="nav-item">
                                <a
                                  class="nav-link"
                                  onClick={() => onChangeTab("land")}
                                  id="custom-tabs-four-messages-tab"
                                  data-toggle="pill"
                                  href="#custom-tabs-four-messages"
                                  role="tab"
                                  aria-controls="custom-tabs-four-messages"
                                  aria-selected="false"
                                >
                                  Land
                                </a>
                              </li>
                              <li class="nav-item">
                                <a
                                  class="nav-link"
                                  onClick={() => onChangeTab("landdownload")}
                                  id="custom-tabs-four-messages-tab"
                                  data-toggle="pill"
                                  href="#custom-tabs-four-messages"
                                  role="tab"
                                  aria-controls="custom-tabs-four-messages"
                                  aria-selected="false"
                                >
                                  Land Download
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div class="card-body">
                            <RowSelectAggridComponent
                              perPage={perPage}
                              currentPage={currentPage}
                              onRowClicked={onRowClicked}
                              onGridReady={onGridReady}
                              onSelectionChanged={onSelectionChanged}
                              rowData={rowData}
                              defaultColDef={defaultColDef}
                              columnDefs={columnDefs}
                              total={total}
                              onPagination={onPagination}
                              onPerPageChange={onPerPageChange}
                              gridApiRef={gridApiRef}
                              onFilterChanged={onFilterChanged}
                              onSortChanged={onSortChanged}
                              getRowStyle={getRowStyle}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <SpinnerComponent spinner={spinner || listSpinner} />
      </div>

      <ViewLoopNet
        rejected={onChangeStatus}
        tabType={tabType}
        data={viewData}
        handleCloseModal={handleCloseViewModal}
        showModal={showViewModal}
      />

      <Modal show={showModal}>
        <Modal.Header>
          <Modal.Title>Create PDF</Modal.Title>
          <i
            className="fa fa-times close_icon_modal"
            onClick={handleCloseModal}
          ></i>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label className="form-label">
                  Buyer's Equity
                  <span className="ml-1" style={{ color: "red" }}>
                    *
                  </span>
                </label>
                <div class="input-group">
                  <input
                    type="text"
                    className="form-control"
                    name="downpayment"
                    value={downpayment}
                    onChange={onChange}
                    placeholder="Enter Buyer's Equity"
                    autoComplete="nope"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">
                      <i class="fa fa-percent"></i>
                    </span>
                  </div>
                </div>
                <div
                  className="invalid-feedback"
                  style={{ display: downpaymentError ? "block" : "none" }}
                >
                  Buyer's Equity is required
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="form-group">
                <label className="form-label">
                  Term Of Loan In Years
                  <span className="ml-1" style={{ color: "red" }}>
                    *
                  </span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="termofloan"
                  value={termofloan}
                  onChange={onChange}
                  placeholder="Enter Term Of Loan In Years"
                  autoComplete="nope"
                />
                <div
                  className="invalid-feedback"
                  style={{ display: termofloanError ? "block" : "none" }}
                >
                  Term Of Loan In Years is required
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="form-group">
                <label className="form-label">
                  Interest Percentage
                  <span className="ml-1" style={{ color: "red" }}>
                    *
                  </span>
                </label>
                <div class="input-group">
                  <input
                    type="text"
                    className="form-control"
                    name="interestpercentage"
                    value={interestpercentage}
                    onChange={onChange}
                    placeholder="Interest Percentage"
                    autoComplete="nope"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">
                      <i class="fa fa-percent"></i>
                    </span>
                  </div>
                </div>
                <div
                  className="invalid-feedback"
                  style={{
                    display: interestpercentageError ? "block" : "none",
                  }}
                >
                  Interest Percentage is required
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="form-group">
                <label className="form-label">
                  CapEx, Working Capital, Closing Costs
                </label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="fas fa-dollar-sign"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    class="form-control"
                    name="additionalcost"
                    value={additionalcost}
                    onChange={onChange}
                    placeholder="Enter additional costs"
                    autoComplete="nope"
                  />
                </div>
              </div>
            </div>

            <div className="col-12">
            <div className="form-group">
                <label className="form-label">Financing Options Leads  <span className="ml-1" style={{ color: "red" }}>
                    *
                  </span></label>
                <Select
                            value={financelead ? financelead : ""}
                            onChange={onChangeSelect}
                            options={fincialleadOptions}
                          />
                 <div
                  className="invalid-feedback"
                  style={{ display: FinancialError ? "block" : "none" }}
                >
                  Financial Lead is required
                </div>
            </div>
        </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            <i className="fa fa-times mr-2"></i>Close
          </Button>
          {saving ? (
            <Button variant="primary">
              <i className="fa fa-spinner fa-spin mr-2"></i>Downloading
            </Button>
          ) : (
            <Button variant="primary" onClick={handleSubmit}>
              <i className="fas fa-download mr-2"></i>Download
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ListLoopnet;
