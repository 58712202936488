import axios from 'axios';
import ImportedURL from '../Common/api';

export function fetchListUser(formData = {}) {
    return function (dispatch) {
        return axios.post(ImportedURL.API.listuser, formData)
            .then((res) => {
                dispatch({ type: "LIST_USER", payload: res.data && res.data.data ? res.data.data : [], total: res.data && res.data.total ? res.data.total : [], spinner: false })
                return res.data;
            }).catch((err) => {
                dispatch({ type: "LIST_USER", payload: [], total: 0, spinner: false })
            });
    };
}

export function fetchViewUser(data) {
    return function (dispatch) {
        dispatch({ type: "VIEW_USER", payload: data, spinner: false })
    };
}

export function fetchUserSpinner() {
    return function (dispatch) {
        dispatch({ type: "SPINNER_USER" })
    };
}

export function fetchUserModalSpinner() {
    return function (dispatch) {
        dispatch({ type: "MODAL_SPINNER_USER" })
    };
}

export function fetchHandleInputUser(name, value) {
    return function (dispatch) {
        dispatch({ type: "HANDLE_USER_CHANGE", name: name, value: value })
    };
}

export function fetchResetUser() {
    return function (dispatch) {
        dispatch({ type: "RESET_USER" })
    };
}