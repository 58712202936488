import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import ImportedURL from '../../Common/api';
import { Error, Success } from '../../Common/swal';
import axios from 'axios';
import Logo from '../../Assets/Images/cornovuscapital-logo.png';
import validator from 'validator';

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [saveSpinner, setSaveSpinner] = useState(false);
    const [passwordstatus, setPasswordstatus] = useState(true);
    const [emailValidError, setEmailValidError] = useState(false);

    const onchange = (e) => {
        const { value, name } = e.target;
        if (name == 'email') {
            let emailValidErrorFlag = false;
            if (value) {
                if (!validator.isEmail(value)) {
                    emailValidErrorFlag = true;
                } else {
                    const tld = value.split('.').pop();
                    if (tld.length < 2 || tld.length > 3) {
                        emailValidErrorFlag = true;
                    }
                }
            }
            setEmailValidError(emailValidErrorFlag);
            setEmail(value);
            setEmailError(false);
        }
        if (name == 'password') {
            setPassword(value);
            setPasswordError(false);
        }
    }

    const submit = () => {
        const formData = {
            email: email ? (email.trim()) : "",
            password: password ? password.trim() : "",
        }
        let valid = 1;
        if (!formData.email) {
            setEmailError(true);
            valid = 0;
        }
        if (emailValidError) {
            valid = 0;
        }
        if (!formData.password) {
            setPasswordError(true);
            valid = 0;
        }
        if (valid) {
            setSaveSpinner(true);
            axios.post(ImportedURL.API.login, formData)
                .then((res) => {
                    const { message, token, role } = res.data ? res.data : {};
                    if (token) {
                        Success(message);
                        setSaveSpinner(false);
                        localStorage.setItem('cornovuscapitaltoken', token);
                        localStorage.setItem('role', role);
                        window.location.href = "/";
                    }
                }).catch(({ response }) => {
                    const { message } = response.data ? response.data : {}
                    Error(message);
                    setSaveSpinner(false);
                });
        }
    }

    return (
        <>
            <div class="login-box">
                <div class="card card-outline card-primary">
                    <div class="card-header text-center">
                        <img src={Logo} alt="AdminLTE Logo" width={"310px"} />
                    </div>
                    <div class="card-body">
                        <p class="login-box-msg">Sign in</p>
                        <form>
                            <div class="input-group mb-3">
                                <input type="email" class="form-control" placeholder="Email" name='email' value={email ? email : ''} onChange={onchange} />
                                <div class="input-group-append">
                                    <div class="input-group-text">
                                        <span class="fas fa-envelope"></span>
                                    </div>
                                </div>
                                <div className="invalid-feedback" style={{ display: emailError ? "block" : 'none' }}>Email is required</div>
                                <div className="invalid-feedback" style={{ display: emailValidError ? "block" : 'none' }}>Enter valid email</div>
                            </div>
                            <div class="input-group mb-3">
                                <input type={passwordstatus ? "password" : "text"} class="form-control" placeholder="Password" name='password' value={password ? password : ''} onChange={onchange} />
                                <div class="input-group-append">
                                    <div class="input-group-text" style={{ cursor: 'pointer' }} onClick={(e) => setPasswordstatus(!passwordstatus)}>
                                        {
                                            passwordstatus
                                                ?
                                                <span class="fas fa-lock"></span>
                                                :
                                                <span class="fas fa-unlock"></span>
                                        }
                                    </div>
                                </div>
                                <div className="invalid-feedback" style={{ display: passwordError ? "block" : 'none' }}>Password is required</div>
                            </div>
                            <p class="mb-2">
                                <Link to="/forgot-password">I forgot my password</Link>
                            </p>
                            <div class="row">
                                <div class="col-12">
                                    {saveSpinner ?
                                        <button type="button" class="btn btn-primary btn-block"><i class="fa fa-spinner fa-spin mr-2"></i>Sign In</button>
                                        :
                                        <button type="button" class="btn btn-primary btn-block" onClick={submit}><i class="fas fa-sign-in-alt mr-2"></i>Sign In</button>
                                    }
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </>
    )
}

export default Login
