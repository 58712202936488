const initialState = {
    spinner: false,
    dashboardcount: {},
};

const dataReducer = (state = initialState, action) => {
    switch (action.type) {
        case "DASHBOARD_SPINNER":
            return {
                ...state,
                spinner: true
            };
        case "DASHBOARD_COUNT":
            return {
                ...state,
                spinner: action.spinner,
                dashboardcount: action.payload
            };

        default:
            return state;
    }
};

export default dataReducer;
