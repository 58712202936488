import React from 'react';

const SpinnerComponent = ({ spinner, showFullPageSpinner = false }) => {
    let id = document.getElementsByClassName("sidebar-collapse")
    return (
        <>
            {spinner &&
                <div className="spinner-overlay" style={{ width: ((id && id.length > 0) || showFullPageSpinner) ? "100%" : "90%" }}>
                    <div className="spinner-container">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default SpinnerComponent;
