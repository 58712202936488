import axios from 'axios';
import ImportedURL from '../Common/api';
import { compress } from 'lz-string';

export function fetchCityData(formData = {}) {
    return function (dispatch) {
        return axios.post(ImportedURL.API.listcitiesandstate, formData)
            .then((res) => {
                const { citylist, statelist } = res.data.data ? res.data.data : {};
                const compressedCitylist = compress(JSON.stringify(citylist));
                const compressedStatelist = compress(JSON.stringify(statelist));
                try {
                    if (citylist && citylist.length > 0) {
                        localStorage.setItem('citylist', compressedCitylist);
                    }
                    if (statelist && statelist.length > 0) {
                        localStorage.setItem('statelist', compressedStatelist);
                    }
                } catch (error) {
                    console.error("Error storing data in local storage:", error);
                }
                dispatch({ type: "CITY_LIST_DATA", payload: citylist || [], statelist: statelist || [], spinner: false })
            }).catch((err) => {
                dispatch({ type: "CITY_LIST_DATA", payload: [], statelist: [], spinner: false })
            });
    };
}

export function listFetchData(formData = {}) {
    return function (dispatch) {
        return axios.post(ImportedURL.API.listvalidcities, formData)
            .then((res) => {
                const { data, total } = res.data ? res.data : {}
                dispatch({ type: "LIST_FETCH_DATA", payload: data ? data : [], total: total ? total : 0, spinner: false })
            }).catch((err) => {
                dispatch({ type: "LIST_FETCH_DATA", payload: [], total: 0, spinner: false })
            });
    };
}

export function getCityAndStates() {
    return function (dispatch) {
        return axios.post(ImportedURL.API.getcityandstatelist)
            .then((res) => {
                const { citylist, statelist } = res.data.data ? res.data.data : {};
                dispatch({ type: "GET_CITY_AND_SALES", payload: citylist || [], statelist: statelist || [] })
            }).catch((err) => {
                dispatch({ type: "GET_CITY_AND_SALES", payload: [], total: 0, spinner: false })
            });
    };
}

export function fetchDataSpinner() {
    return function (dispatch) {
        dispatch({ type: "FETCH_DATA_SPINNER" })
    };
}